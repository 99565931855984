import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  NavLink,
} from 'react-router-dom';
import {
  Box,
  Button,
  Text,
  Image,
  Link,
  Center,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Timeline.css';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import p1 from '../assets/step1.png';
import p2 from '../assets/step2.png';
import p3 from '../assets/step3.png';

export default function Timeline() {
  const data = [
    {
      title: 'Find yourself',
      description:
        'Curated by experts, a psychometric test will help you better understand your personality, strengths, and weaknesses, make informed career decisions, and identify areas for improvement and personal growth.',
      button: 'Take the free test',
      link: '/test',
    },
    {
      title: 'Map your Future',
      description:
        '1 -on-1 counseling where students and their parents can sit in the comfort of their homes and take the session to assist them in finding fulfilling and successful careers that align with their interests, values, and skills.',
      button: 'Book my Session',
      link: '/services',
    },
    {
      title: 'Join the Community',
      description:
        'Be a part of a community where you can find like-minded individuals,  experts, and a mentor to help you perfect your strengths through fun activities, workshops, and much more!',
      button: 'Explore',
      link: '/community',
    },
  ];

  return (
    <Box
      id='timeline'
      w={{ base: '100%', md: '80%' }}
      py={{ base: '50px', md: '100px' }}
      // ml={{ base: '', md: '10%' }}
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      gap='30px'
      // bg='red'
    >
      <Box w='100%'>
        <EventL no='1' data={data[0]} img={p1} />
      </Box>

      <Box w='100%' display={{ base: 'block', md: 'none' }}>
        <EventL no='2' data={data[1]} img={p2} />
      </Box>

      <Box w='100%' display={{ base: 'none', md: 'block' }}>
        <EventR no='2' data={data[1]} img={p2} />
      </Box>

      <Box w='100%'>
        <EventL no='3' data={data[2]} img={p3} />
      </Box>
    </Box>
  );
}

function EventL(props) {
  return (
    <Box
      w='100%'
      display='flex'
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: '30px', sm: '10px' }}
      bg=''
      mb='10px'
    >
      <VStack
        position='relative'
        w={{ base: '100%', md: '60%' }}
        minH={{ base: '250px', md: '300px' }}
        data-aos='fade-right'
        p='15px'
        justifyContent={'space-around'}
        borderRadius={'20px'}
        boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
        bg='white'
      >
        <Box
          // bg='blue'
          w='100%'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          pr={{ base: '0', md: '10px' }}
        >
          <Text
            // bg='violet'
            w='100%'
            textAlign={'center'}
            pl={{ base: '50px', lg: '65px' }}
            fontWeight='bold'
            fontSize={{ base: '25px', sm: '30px', md: '30px', lg: '40px' }}
          >
            {props.data.title}
          </Text>
          <Center
            minW={{ base: '50px', lg: '65px' }}
            minH={{ base: '50px', lg: '65px' }}
            borderRadius='50px'
            bg='black'
          >
            <Text
              color='white'
              // textShadow='-4px 4px black'
              fontSize={{ base: '30px', md: '40px' }}
              fontWeight='bold'
            >
              {props.no}
            </Text>
          </Center>
        </Box>

        <Box w='80%' mb={{ base: '15px', md: '30px' }} data-aos='fade-up'>
          <Text
            textAlign={'justify'}
            color='#585858'
            fontSize={{ base: '16px', md: '20px' }}
          >
            {props.data.description}
          </Text>
        </Box>

        <Button
          className='group'
          variant='unstyled'
          minW={{ base: '80px', md: '140px' }}
          display='flex'
          color='white'
          p='10px'
          // bg='linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'
          bg='black'
          borderRadius={'100px'}
          as={NavLink}
          to={props.data.link}
        >
          <Text px='20px' fontSize={{ base: '13px', md: '17px' }}>
            {props.data.button}
          </Text>
          {/* <ArrowForwardIcon ml='10px' /> */}
        </Button>
      </VStack>

      <Box
        w={{ base: '100%', md: '40%' }}
        h={{ base: '250px', md: '300px' }}
        display='flex'
        justifyContent='center'
        alignItems='center'
        data-aos='fade-left'
        // bg='aqua'
      >
        <Image
          boxSize={{ base: '250px', md: '300px' }}
          objectFit='cover'
          src={props.img}
        />
      </Box>
    </Box>
  );
}

function EventR(props) {
  return (
    <Box
      w='100%'
      display='flex'
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: '30px', sm: '10px' }}
      bg=''
      mb='10px'
    >
      <Box
        w={{ base: '100%', md: '40%' }}
        h={{ base: '250px', md: '300px' }}
        display='flex'
        justifyContent='center'
        alignItems='center'
        data-aos='fade-right'
        // bg='aqua'
      >
        <Image
          boxSize={{ base: '250px', md: '300px' }}
          objectFit='cover'
          src={props.img}
        />
      </Box>

      <VStack
        position='relative'
        w={{ base: '100%', md: '60%' }}
        minH={{ base: '250px', md: '300px' }}
        data-aos='fade-left'
        justifyContent={'space-around'}
        p='15px'
        borderRadius={'20px'}
        boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
        bg='white'
      >
        <Box
          // bg='blue'
          w='100%'
          display='flex'
          alignItems='center'
          gap='15px'
          // justifyContent='space-between'
          pl={{ base: '0', md: '10px' }}
        >
          <Center
            minW={{ base: '50px', lg: '65px' }}
            minH={{ base: '50px', lg: '65px' }}
            borderRadius='50px'
            bg='black'
          >
            <Text
              color='white'
              // textShadow='-4px 4px black'
              fontSize={{ base: '30px', md: '40px' }}
              fontWeight='bold'
            >
              {props.no}
            </Text>
          </Center>
          <Text
            // bg='violet'
            w='100%'
            textAlign={'center'}
            fontWeight='bold'
            fontSize={{ base: '25px', sm: '30px', md: '30px', lg: '40px' }}
            pr={{ base: '50px', lg: '65px' }}
          >
            {props.data.title}
          </Text>
        </Box>

        <Box w='80%' mb={{ base: '15px', md: '30px' }} data-aos='fade-up'>
          <Text
            textAlign={'justify'}
            color='#585858'
            fontSize={{ base: '16px', md: '20px' }}
          >
            {props.data.description}
          </Text>
        </Box>

        <Button
          className='group'
          variant='unstyled'
          minW={{ base: '80px', md: '140px' }}
          display='flex'
          color='white'
          p='10px'
          // bg='linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'
          bg='black'
          borderRadius={'100px'}
          as={NavLink}
          to={props.data.link}
        >
          <Text px='20px' fontSize={{ base: '13px', md: '17px' }}>
            {props.data.button}
          </Text>
          {/* <ArrowForwardIcon ml='10px' /> */}
        </Button>
      </VStack>
    </Box>
  );
}
