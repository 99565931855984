import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  HStack,
  VStack,
  Center,
  Button,
  Icon,
  Progress,
  useToast,
  useColorModeValue,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import Top from '../Components/Top';
import j1 from '../assets/j11.png';
import j2 from '../assets/j21.png';
import j4 from '../assets/j31.png';
import j3 from '../assets/j41.png';
import j5 from '../assets/j51.png';
import ans1 from '../assets/ans1.png';
import ans2 from '../assets/ans2.png';
import ans3 from '../assets/ans3.png';
import ans4 from '../assets/ans4.png';
import ans5 from '../assets/ans5.png';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../Context/GlobalContext';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

export default function BasicTest() {
  const [startTest, setStartTest] = useState(false);
  const [finishTest, setFinishTest] = useState(false);
  const [qNo, setQNo] = useState(0);
  const questions = [
    'I enjoy analyzing complex problems and finding logical solutions.',
    'I am comfortable working with data and numbers.',
    'I prefer working in a team rather than working independently.',
    'I am able to handle stress and pressure effectively.',
    'I enjoy taking on new challenges and learning new things.',
    'I enjoy creative activities such as art, music, or writing.',
    'It is important for me to have a sense of purpose and meaning in my work.',
    'I am comfortable taking risks and making decisions.',
    'I am interested in technology and enjoy learning new software or programming languages.',
    'I am comfortable presenting in front of groups or public speaking.',
    'I prefer working on long-term projects rather than short-term tasks.',
    'I enjoy helping others and making a positive impact in their lives.',
    'I am comfortable with conflict and able to resolve difficult conversations.',
    'I am adaptable and can handle change easily.',
    'I prefer a structured work environment rather than a flexible one.',
    'I value work-life balance and the importance of having time for personal pursuits.',
    'I enjoy problem-solving and critical thinking.',
    'I enjoy building or creating things with my hands.',
    'I am able to handle constructive criticism and feedback effectively.',
    'It is important for me to have a creative outlet outside of work.',
  ];
  const [marks, setMarks] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [profileNo, setProfileNo] = useState(0);
  var score = 0;
  var count = 0;
  const toast = useToast();

  const navigate = useNavigate();

  const profiles = [
    {
      title: 'Lab Luminary',
      description:
        'The student has high analytical and logical thinking skills and a strong aptitude for problem-solving and critical thinking. They also have a keen interest in exploring the underlying principles and mechanisms of the natural world and may be driven by a curiosity to understand complex systems and phenomena.',
      color: '#91ec98',
      bg: '#3b6a4c',
      img: j1,
      stream: 'Science',
      degree: [
        'B.Sc.- Physics',
        'B.Sc. Chemistry',
        'B.Sc. Mathematics',
        'M.B.B.S',
        'BE/B.Tech- Bachelor of Technology',
        'BCA- Bachelor of Computer Applications',
        'B.Sc. – Nutrition & Dietetics',
        'BPT- Bachelor of Physiotherapy',
        'B.Sc- Applied Geology',
        'BDS- Bachelor of Dental Surgery',
        'B.Sc.- Information Technology',
        'B.Sc- Nursing',
        'BPharma- Bachelor of Pharmacy',
      ],
    },
    {
      title: 'Tech Titan',
      description:
        'The student has a high level of analytical and problem-solving abilities and a strong interest in understanding how things work. They naturally gravitate towards math and science and enjoy hands-on experimentation and exploration. They are detail-oriented and systematic in their approach to tasks and have the desire to create innovative solutions to complex problems.',
      color: '#91e2ecab',
      bg: '#c6b196',
      img: j2,
      stream: 'Science, Commerce',
      degree: [
        'BE/B.Tech- Bachelor of Technology',
        'B.Arch- Bachelor of Architecture',
        'BCA- Bachelor of Computer Applications',
        'B.Sc.- Information Technology',
        'B.Sc. Mathematics',
        'Animation, Graphics and Multimedia',
        'Aviation Courses',
      ],
    },
    {
      title: 'Civic Sovereign',
      description:
        'The student has analytical solid and critical thinking skills, along with a desire to serve the public and positively impact society. They possess excellent communication, interpersonal, and organizational skills, a strong work ethic, and a commitment to ethical behavior. They are naturally inclined towards public policy, government administration, or public service leadership.',
      color: '#ec9191ab',
      bg: '#c33514',
      img: j3,
      stream: 'Commerce, Humanities',
      degree: [
        'Integrated Law Course- BA + LL.B',
        'BJMC- Bachelor of Journalism and Mass Communication',
        'BSW- Bachelor of Social Work',
        'BA in History',
        'BTTM- Bachelor of Travel and Tourism Management',
        'BA/B.Sc. Liberal Arts',
        'BA (Hons.) in Economics',
        'Integrated Law Program- B.Com LL.B',
        'Integarted Law Program- BBA LL.B',
        'Bachelor in Foreign Language',
        'BEM- Bachelor of Event Management',
      ],
    },
    {
      title: 'Money Maestro',
      description:
        'The student is strongly interested in numbers and statistics and naturally inclined toward math and data-driven decision-making. Detail-oriented and systematic in their approach to tasks, with a high level of precision and accuracy, and also possess excellent communication and presentation skills',
      color: '#d8f053bd',
      bg: '#f5bb28',
      img: j4,
      stream: 'Commerce, Humanities',
      degree: [
        'Integarted Law Program- BBA LL.B',
        'B.Com- Bachelor of Commerce',
        'B.Com (Hons.)',
        'BA (Hons.) in Economics',
        'BBS- Bachelor of Business Studies',
        'BTTM- Bachelor of Travel and Tourism Management',
        'BEM- Bachelor of Event Management',
        'BBA- Bachelor of Business Administration',
        'BMS- Bachelor of Management Science',
        'B.Sc.- Hospitality and Hotel Administration',
      ],
    },
    {
      title: 'Design Diva',
      description:
        'The student has high creativity, imagination, and aesthetic sensitivity. They are naturally inclined towards visual or performing arts, with a strong interest in exploring new and innovative ways of expressing themselves through art, possess a strong sense of individuality, excellent observational and interpretive skills, and a desire to communicate their ideas and emotions through their art.',
      color: '#e991ecab',
      bg: '#8661c1',
      img: j5,
      stream: 'Science, Humanities',
      degree: [
        'BFA- Bachelor of Fine Arts',
        'B.Sc- Interior Design',
        'Bachelor of Design (B. Design)',
        'Bachelor of Performing Arts',
        'Animation, Graphics and Multimedia',
        'BFD- Bachelor of Fashion Designing',
        'B.Arch- Bachelor of Architecture',
      ],
    },
  ];

  const { profile, setProfile, setShowLogin } = useContext(GlobalContext);

  function updateMarks(mark) {
    const markArray = [...marks];
    markArray[qNo] = mark;
    setMarks(markArray);
    qNo === 19 ? setQNo(19) : setQNo(qNo + 1);
  }
  const handleSubmit = async () => {
    count = 0;
    marks.forEach(x => {
      if (x != 0) count += 1;
    });
    if (count === 20) {
      marks.forEach(x => {
        score += x;
      });
      let data = {};
      if (score >= 80) {
        setProfileNo(0);
        setProfile(profiles[0].title);
        data.profile = profiles[0].title;
      }
      if (score >= 75 && score <= 79) {
        setProfileNo(1);
        setProfile(profiles[1].title);
        data.profile = profiles[1].title;
      }
      if (score >= 70 && score <= 74) {
        setProfileNo(3);
        setProfile(profiles[3].title);
        data.profile = profiles[3].title;
      }
      if (score >= 65 && score <= 69) {
        setProfileNo(2);
        setProfile(profiles[2].title);
        data.profile = profiles[2].title;
      }
      if (score <= 64) {
        setProfileNo(4);
        setProfile(profiles[4].title);
        data.profile = profiles[4].title;
      }
      try {
        const response = await axios.post(
          `https://luciddreams.in/api/update/profile`,
          // `https://luciddreams-site.onrender.com/api/update/profile`,
          // `http://localhost:5000/api/update/profile`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status == 200) {
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
        // toast({
        //   title: 'Error',
        //   description: error.response.data.message,
        //   status: 'error',
        //   duration: 10000,
        //   isClosable: true,
        // });
      }
      setFinishTest(true);
      setStartTest(false);
    } else {
      toast({
        title: 'Error',
        description: 'Please answer all questions to submit.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    console.log(marks);
    console.log(score);
    console.log(count);
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <VStack
      w='100vw'
      // h='100vh'
      // bg='red'
      py={{ base: '50px', md: '100px' }}
      overflow='hidden'
      spacing='50px'
    >
      {!startTest && !finishTest && (
        <VStack spacing='50px' w='100%'>
          <Text
            fontFamily='JakartaVariable'
            fontWeight='extrabold'
            fontSize={{ base: '35px', md: '45px' }}
          >
            Lucid Profiling Test
          </Text>
          <VStack w={{ base: '95%', md: '80%' }} alignItems={'left'}>
            <Text fontWeight='semibold' fontSize={'30px'}>
              Instructions
            </Text>
            <UnorderedList pl='20px'>
              <ListItem>
                This test is based on extensive research by experts in the
                fields of child psychology and psychometric testing designed to
                help you gain insights into your personality traits and
                cognitive abilities, which can help you make informed decisions
                about your education, career, and personal development.{' '}
              </ListItem>
              <ListItem>
                The test categorizes students into one of five profiles, each
                with its unique set of strengths and weaknesses.{' '}
              </ListItem>
              <ListItem>
                The test consists of 20 multiple-choice questions, and all
                questions are mandatory. There is no time limit, so take as much
                time as you need to answer each question. Each question will
                present you with a set of options to choose from. Select the
                option that best applies to you. Make sure to read each question
                carefully before selecting your answer.{' '}
              </ListItem>
              <ListItem>
                If you're unsure about an answer, go with your first instinct.
                Remember, there are no right or wrong answers, so be honest with
                yourself when answering the questions.
              </ListItem>
            </UnorderedList>
          </VStack>

          <Stack
            justifyContent={'center'}
            alignItems={'baseline'}
            gap={{ base: '15px', md: '20px' }}
            w={{ base: '95%', md: '80%' }}
            flexFlow={'row wrap'}
          >
            <Profile bg='#3b6a4c' name='Lab Luminary' img={j1} />
            <Profile bg='#c6b196' name='Tech Titan' img={j2} />
            <Profile bg='#c33514' name='Civil Sovereign' img={j3} />
            <Profile bg='#f5bb28' name='Money Maestro' img={j4} />
            <Profile bg='#8661c1' name='Design Diva' img={j5} />
          </Stack>
          <Button
            // w={{ base: '300px', md: '500px' }}
            h='50px'
            bg='#109dab'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='5px'
            fontSize={{ base: '1rem', md: '1.5rem' }}
            px={{ base: '15px', md: '30px' }}
            _hover={{}}
            onClick={() => {
              if (localStorage.getItem('isLogin') === 'true') {
                setStartTest(true);
              } else {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
                setShowLogin(true);
              }
            }}
          >
            Start Test
          </Button>
        </VStack>
      )}
      {startTest && !finishTest && (
        <VStack spacing='50px' w={{ base: '95%', md: '80%' }} h='100vh'>
          <Text
            w='100%'
            textAlign={'left'}
            fontWeight='semibold'
            fontSize={'25px'}
          >
            Question {qNo + 1}
          </Text>
          <Text
            w='100%'
            textAlign={'center'}
            fontWeight='semibold'
            fontSize={{ base: '20px', md: '30px' }}
          >
            {questions[qNo]}
          </Text>
          <HStack
            w={{ base: '100%', md: '60%' }}
            justifyContent={'space-around'}
            alignItems={'flex-start'}
          >
            <VStack>
              <Image
                _hover={{ transform: 'scale(1.1)' }}
                transitionDuration={'0.5s'}
                onClick={() => updateMarks(1)}
                boxSize='60px'
                src={ans1}
              />
              <Text>Strongly Disagree</Text>
            </VStack>
            <Image
              _hover={{ transform: 'scale(1.1)' }}
              transitionDuration={'0.5s'}
              onClick={() => updateMarks(2)}
              boxSize='60px'
              src={ans2}
            />
            <Image
              _hover={{ transform: 'scale(1.1)' }}
              transitionDuration={'0.5s'}
              onClick={() => updateMarks(3)}
              boxSize='60px'
              src={ans3}
            />
            <Image
              _hover={{ transform: 'scale(1.1)' }}
              transitionDuration={'0.5s'}
              onClick={() => updateMarks(4)}
              boxSize='60px'
              src={ans4}
            />
            <VStack>
              <Image
                _hover={{ transform: 'scale(1.1)' }}
                transitionDuration={'0.5s'}
                onClick={() => updateMarks(5)}
                boxSize='60px'
                src={ans5}
              />
              <Text>Strongly Agree</Text>
            </VStack>
          </HStack>
          <HStack w='100%' justifyContent={'space-between'}>
            <Button
              // w={{ base: '300px', md: '500px' }}
              boxSize='50px'
              // border='1px solid #109dab'
              bg='black'
              color='#109dab'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='50px'
              fontSize={{ base: '15px', md: '20px' }}
              _hover={{}}
              onClick={() => {
                qNo === 0 ? setQNo(0) : setQNo(qNo - 1);
              }}
            >
              <ArrowLeftIcon />
            </Button>
            <Button
              bg='#109dab'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='5px'
              fontSize={{ base: '15px', md: '20px' }}
              p={{ base: '10px', md: '20px' }}
              _hover={{}}
              onClick={handleSubmit}
              // isDisabled={qNo === 19 ? false : true}
            >
              Submit
            </Button>
            <Button
              // w={{ base: '300px', md: '500px' }}
              boxSize='50px'
              // border='1px solid #109dab'
              bg='black'
              color='#109dab'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='50px'
              fontSize={{ base: '15px', md: '20px' }}
              _hover={{}}
              onClick={() => {
                qNo === 19 ? setQNo(19) : setQNo(qNo + 1);
              }}
              // isDisabled={watched ? false : true}
            >
              <ArrowRightIcon />
            </Button>
          </HStack>
          <SimpleGrid
            columns={{ base: 10, md: 20 }}
            spacing={{ base: '5px', md: '10px' }}
          >
            {marks.map((mark, index) => (
              <Center
                boxSize={{ base: '25px', md: '30px' }}
                fontSize={{ base: '12px', md: '16px' }}
                bg={mark === 0 ? 'red' : 'green'}
                borderRadius={'20px'}
                onClick={() => {
                  setQNo(index);
                }}
                cursor={'pointer'}
              >
                {index + 1}
              </Center>
            ))}
          </SimpleGrid>
          {/* <Progress
            borderRadius={'30px'}
            w='100%'
            colorScheme='linkedin'
            size='md'
            value={(qNo + 1) / 0.2}
          /> */}
        </VStack>
      )}
      {finishTest && (
        <VStack spacing='30px' w='100%'>
          <Text
            fontFamily='JakartaVariable'
            fontWeight='extrabold'
            fontSize={{ base: '35px', md: '45px' }}
          >
            Your Profile
          </Text>
          <VStack spacing='30px'>
            <Image
              boxSize='250px'
              boxShadow={`0 0 20px 1px ${profiles[profileNo].bg}`}
              src={profiles[profileNo].img}
              border='1px solid #aeaeae'
              borderRadius='200px'
            />
            <Text
              p='10px'
              fontSize={'30px'}
              fontWeight={'bold'}
              bg={profiles[profileNo].bg}
              borderRadius={'10px'}
            >
              {profiles[profileNo].title}
            </Text>
          </VStack>
          <Text
            w={{ base: '90%', md: '60%' }}
            color='#808080'
            textAlign='justify'
            fontWeight='normal'
            fontSize={{ base: '15px', md: '20px' }}
          >
            {profiles[profileNo].description}
          </Text>
          <HStack
            w={{ base: '90%', md: '60%' }}
            fontSize={{ base: '1rem', md: '1.5rem' }}
            spacing={'1rem'}
          >
            <Text fontWeight='semibold'>Ideal Streams:</Text>
            <Text>{profiles[profileNo].stream}</Text>
          </HStack>
          <VStack w={{ base: '90%', md: '60%' }} spacing={'1rem'}>
            <Text
              w='100%'
              fontSize={{ base: '1rem', md: '1.5rem' }}
              fontWeight='semibold'
            >
              Ideal Degrees:
            </Text>
            <SimpleGrid
              columns={{ base: '1', sm: '2', lg: '3' }}
              spacing={'1rem'}
            >
              {profiles[profileNo].degree.map(degree => (
                <HStack
                  w='100%'
                  px={'1rem'}
                  py={'0.5rem'}
                  minH={{ base: '30px', md: '80px' }}
                  bg={profiles[profileNo].color}
                  borderRadius={'10px'}
                  boxShadow={'0 0 10px 1px #d1d1d1'}
                >
                  <Text
                    fontSize={{ base: '1rem', md: '1.2rem' }}
                    fontWeight={'bold'}
                  >
                    {degree}
                  </Text>
                </HStack>
              ))}
            </SimpleGrid>
          </VStack>
          <HStack pt='50px'>
            <Button
              w={{ base: '165px', md: '200px' }}
              h='50px'
              bg='linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='15px'
              fontSize={{ base: '0.8rem', md: '1rem' }}
              p='10px'
              _hover={{}}
              onClick={() => {
                navigate('/services');
              }}
            >
              Check out Lucid Plus
            </Button>
            <Button
              w={{ base: '165px', md: '200px' }}
              h='50px'
              // border='1px solid #109dab'
              bg='black'
              color='#8C49F7'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='15px'
              fontSize={{ base: '0.8rem', md: '1rem' }}
              p='10px'
              _hover={{}}
              as={NavLink}
              to={'/test/basictest'}
              onClick={() => {
                toast({
                  title: 'Thank You',
                  description: 'Our experts will get in touch with you soon.',
                  status: 'success',
                  duration: 10000,
                  isClosable: true,
                });
              }}
            >
              Get a Free Analysis
            </Button>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}

function Profile(props) {
  return (
    <VStack spacing='1.3rem'>
      <Image
        boxSize={{ base: '150px', md: '200px' }}
        boxShadow={`0 0 20px 1px ${props.bg}`}
        src={props.img}
        borderRadius={'15px'}
        border='1px solid #aeaeae'
        objectFit={'cover'}
      />
      <Text
        p='5px'
        minW='140px'
        textAlign='center'
        fontSize={{ base: '16px', md: '20px' }}
        bg={props.bg}
        borderRadius={'10px'}
      >
        {props.name}
      </Text>
    </VStack>
  );
}
