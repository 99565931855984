// import React, { useEffect, useState } from 'react';
// import {
//   ChakraProvider,
//   Button,
//   Box,
//   Text,
//   keyframes,
//   Link,
//   Stack,
//   VStack,
//   HStack,
//   IconButton,
//   Code,
//   Grid,
//   theme,
//   Image,
// } from '@chakra-ui/react';
// import {
//   FaTwitter,
//   FaFacebookF,
//   FaYoutube,
//   FaInstagram,
//   FaWhatsapp,
//   FaLinkedin,
// } from 'react-icons/fa';

// export default function Footer() {
//   const socials = [
//     {
//       link: 'https://twitter.com/',
//       icon: FaTwitter,
//     },
//     {
//       link: 'https://facebook.com/',
//       icon: FaFacebookF,
//     },
//     {
//       link: 'https://youtube.com/',
//       icon: FaYoutube,
//     },
//     {
//       link: 'https://instagram.com/',
//       icon: FaInstagram,
//     },
//     {
//       link: 'https://youtube.com/',
//       icon: FaWhatsapp,
//     },
//     {
//       link: 'https://youtube.com/',
//       icon: FaLinkedin,
//     },
//   ];

//   return (
//     <Stack
//       w={{ base: '90%', md: '60%' }}
//       justifyContent='space-between'
//       direction={{ base: 'column', md: 'row' }}
//     >
//       <VStack align='start' mb='30px'>
//         <Text color='whiteAlpha.500' _hover={{ color: 'white' }}>
//           Terms and Conditions
//         </Text>
//         <Text color='whiteAlpha.500' _hover={{ color: 'white' }}>
//           Refund Policy
//         </Text>
//       </VStack>
//       <VStack align='start' spacing='10px'>
//         <Text color='whiteAlpha.500'>Connect with US</Text>
//         <HStack spacing='3.5'>
//           {socials.map(soc => (
//             <Link href={soc.link} isExternal>
//               <IconButton
//                 bg='whiteAlpha.700'
//                 color='black'
//                 variant='ghost'
//                 size='sm'
//                 fontSize='20px'
//                 isRound='True'
//                 icon={<soc.icon />}
//               />
//             </Link>
//           ))}
//         </HStack>
//       </VStack>
//     </Stack>
//   );
// }

import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  HStack,
  VisuallyHidden,
  Input,
  Image,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaInstagram,
  FaWhatsapp,
  FaLinkedin,
} from 'react-icons/fa';
import { BiMailSend, BiPhone } from 'react-icons/bi';
import logo from '../assets/whitefont.png';
import { NavLink } from 'react-router-dom';

const socials = [
  {
    link: 'https://twitter.com/',
    icon: FaTwitter,
  },
  {
    link: 'https://www.facebook.com/luciddreams.in/',
    icon: FaFacebookF,
  },
  {
    link: 'https://youtube.com/',
    icon: FaYoutube,
  },
  {
    link: 'https://www.instagram.com/lucid_dreams.in/',
    icon: FaInstagram,
  },
  {
    link: 'https://wa.me/9074359460',
    icon: FaWhatsapp,
  },
  {
    link: 'https://www.linkedin.com/company/luciddreams-in/about/',
    icon: FaLinkedin,
  },
];

export default function Footer() {
  return (
    <VStack
      w='100%'
      // overflow='hidden'
      // bg='red'
      color={useColorModeValue('whiteAlpha.500', 'whiteAlpha.200')}
    >
      <VStack w={{ base: '90%', xl: '70%' }} py={10}>
        <Stack
          w='100%'
          align={'top'}
          justifyContent={'space-between'}
          px='20px'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 6, md: 10, lg: 20 }}
        >
          <VStack spacing={6}>
            <Box>
              {/* <Logo color={useColorModeValue('gray.700', 'white')} /> */}
              <Image w='200px' src={logo} />
            </Box>
            {/* <Text fontSize={'sm'}>
              © 2023 LucidDreams.in . All rights reserved
            </Text> */}
            <VStack align='start' spacing='10px'>
              <Text color='whiteAlpha.500'>Connect with US</Text>
              <HStack spacing='3.5'>
                {socials.map(soc => (
                  <Link href={soc.link} isExternal>
                    <IconButton
                      bg='whiteAlpha.700'
                      color='black'
                      variant='ghost'
                      size='sm'
                      fontSize='20px'
                      isRound='True'
                      icon={<soc.icon />}
                    />
                  </Link>
                ))}
              </HStack>
            </VStack>
          </VStack>

          <VStack align={'flex-start'}>
            <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
              Company
            </Text>
            <Link as={NavLink} to={'/aboutus'}>
              About us
            </Link>
            <Link as={NavLink} to={'/blog'}>
              Blog
            </Link>
            <Link as={NavLink} to={'/contactus'}>
              Contact us
            </Link>
            <Link as={NavLink} to={'/community'}>
              Pricing
            </Link>
            <Link as={NavLink} to={'/'}>
              Testimonials
            </Link>
          </VStack>

          <VStack align={'flex-start'}>
            <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
              Support
            </Text>
            <Link href={'#'}>Help Center</Link>
            <Link as={NavLink} to={'/termsandconditions'}>
              Terms of Service
            </Link>
            <Link as={NavLink} to={'/refundpolicy'}>
              Refund Policy
            </Link>
            <Link as={NavLink} to={'/privacypolicy'}>
              Privacy Policy
            </Link>
          </VStack>

          <VStack align={'flex-start'}>
            <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
              Stay up to date
            </Text>
            <Stack w='300px' direction={'row'}>
              <Input
                placeholder={'Mobile Number'}
                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                border={'1px solid white'}
                _focus={{
                  bg: 'whiteAlpha.300',
                }}
              />
              <IconButton
                bg={useColorModeValue('blue.400', 'blue.800')}
                color={useColorModeValue('white', 'gray.800')}
                _hover={{
                  bg: 'blue.600',
                }}
                aria-label='Subscribe'
                icon={<BiPhone />}
              />
            </Stack>
          </VStack>
        </Stack>
      </VStack>
    </VStack>
  );
}
