import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  keyframes,
  Divider,
  Link,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Stack,
  Icon,
  Center,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  UnorderedList,
  ListItem,
  List,
  ListIcon,
} from '@chakra-ui/react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { GlobalContext } from '../Context/GlobalContext';

import './Services.css';
import ill3 from '../assets/ill3.jpg';
import ill9 from '../assets/ill9.jpg';
import ill10 from '../assets/ill10.jpg';
import gift from '../assets/gift1.png';
import gc1 from '../assets/gc1.png';
import gc2 from '../assets/gc2.png';
import gc3 from '../assets/gc3.png';
import gc4 from '../assets/gc4.png';
import gc5 from '../assets/gc5.png';
import gc6 from '../assets/gc6.png';
import gc7 from '../assets/gc7.png';
import pc1 from '../assets/pc1.png';
import pc2 from '../assets/pc2.png';
import pc3 from '../assets/pc3.png';
import pc4 from '../assets/pc4.png';
import prof1 from '../assets/profile-1.svg';
import prof2 from '../assets/profile-2.svg';
import prof3 from '../assets/profile-3.svg';
import epic from '../assets/epic.png';
import comm1 from '../assets/comm1.png';
import comm2 from '../assets/comm2.png';
import arrow from '../assets/arrowdownblack.png';
import comm1big from '../assets/comm1big.png';
import monday from '../assets/teacup.png';
import tuesday from '../assets/bulb.svg';
import wednesday from '../assets/crossword.png';
import thursday from '../assets/history.svg';
import friday from '../assets/finance.svg';
import saturday from '../assets/books.svg';
import sunday from '../assets/celebration.svg';
import couponCover from '../assets/scratchcard.JPG';
import { FaPlusCircle } from 'react-icons/fa';
import { Textbox } from './Test';
import Top from '../Components/Top';
import { BsArrowDown } from 'react-icons/bs';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import ScratchCard from 'react-scratchcard';
import ConfettiExplosion from 'react-confetti-explosion';

export default function Services() {
  const [isMd] = useMediaQuery('(max-width: 767px)');

  const events = [
    {
      title: 'Monday Morning Tea',
      description: `Join us for a rapid-fire roundup of the latest and greatest events from around the globe! From breaking news to pop culture trends, a fun and energetic format that'll keep you in the know and entertained.
      1 Month Outcome: On-point with current affairs.`,
      img: monday,
    },
    {
      title: 'Tips Tuesday',
      description: `Join us as we explore the latest and most fantastic study techniques, hacks, and tricks to help you solve questions faster and more efficiently. Whether you're prepping for an exam or just looking to sharpen your skills, our fun and informative videos will give you the edge you need to succeed`,
      img: tuesday,
    },
    {
      title: 'Words Wednesday',
      description: `Join us to learn new words and phrases through interactive games and activities that make learning a blast. Whether you're a beginner or an advanced learner, our fresh approach to language education will have you expanding your vocabulary in no time.`,
      img: wednesday,
    },
    {
      title: 'Throwback Thursaday',
      description: `Join us for a trip through history to bring the past to life with fascinating stories and insights about historical events and personalities. From ancient civilizations to modern-day milestones, our fun and engaging approach will keep you entertained and informed
      1 Month Outcome: 5 new stories to connect you with your roots
      `,
      img: thursday,
    },
    {
      title: 'Finance Friday',
      description: `Join us to learn new words and phrases through interactive games and activities that make learning a blast. Whether you're a beginner or an advanced learner, our fresh approach to language education will have you expanding your vocabulary in no time.
      1 Month Outcome: You get the financial education that you are missing out on
      `,
      img: friday,
    },
    {
      title: 'Book of the Week',
      description: `Join us on a live event designed to promote reading habits among students by discussing a new book every two weeks. Indulge in engaging conversations about the characters, themes, and messages behind each book as we explore the fascinating world of literature, connect with like-minded students, and share your thoughts on the must-reads, the perfect place to read or start reading.
      1 Month Outcome: 2 new books added to your library
      `,
      img: saturday,
    },
    {
      title: 'Sunday FunDay',
      description: `Join us at our live online game event packed with fun and excitement as players come together to compete in real-time. We've got something from classic favorites to new and innovative games for everyone. So grab your friends and get ready to play like a pro. Let the fun never stops`,
      img: sunday,
    },
  ];

  const splideFont = {
    base: '30px',
    sm: '40px',
    md: '35px',
    lg: '30px',
    xl: '30px',
    '2xl': '30px',
  };

  const jobs = [
    {
      title: 'Doctor',
      icon: FaPlusCircle,
    },
    {
      title: 'Software Developer',
      icon: FaPlusCircle,
    },
    {
      title: 'Scientist',
      icon: FaPlusCircle,
    },
    {
      title: 'Investment Banker',
      icon: FaPlusCircle,
    },
    {
      title: 'Data Scientist',
      icon: FaPlusCircle,
    },
    {
      title: 'Architect',
      icon: FaPlusCircle,
    },
    {
      title: 'Police ',
      icon: FaPlusCircle,
    },
    {
      title: 'Civil Service',
      icon: FaPlusCircle,
    },
  ];

  const attract = keyframes`
  30% {
    transform: scale(1.05);
  }
  40%,
  60% {
    transform: rotate(-2deg) scale(1.05);
  }
  50% {
    transform: rotate(2deg) scale(1.05);
  }
  70% {
    transform: rotate(0deg) scale(1.05);
  }
  100% {
    transform: scale(1);
  }
  `;

  const attractAnim = `${attract} 2s ease infinite`;

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    onOpen();
  }, []);

  const { showLogin, setShowLogin, isLogin, setIsLogin, setProduct } =
    useContext(GlobalContext);

  const toast = useToast();

  const navigate = useNavigate();

  const scratchsettings = {
    width: 300,
    height: 300,
    image: couponCover,
    finishPercent: 50,
  };
  const [isExploding, setIsExploding] = useState(false);
  const mediumProps = {
    force: 0.6,
    duration: 2500,
    particleCount: 100,
    width: 1000,
    zIndex: 99999,
    colors: ['#9A0023', '#FF003C', '#AF739B', '#FAC7F3', '#F7DBF4'],
  };

  return (
    <Box
      w={{ base: '90%', md: '80%' }}
      maxW='100vw'
      overflowX={'hidden'}
      minH='100vh'
      //   bg='aqua'
      color='black'
      display='flex'
      flexDirection='column'
      justifyContent={'center'}
      alignItems={'center'}
      gap='30px'
      pb='50px'
      pt='50px'
    >
      {/* <Top /> */}

      <Modal size={{ base: 'xs', sm: 'sm', md: 'lg' }} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent zIndex={3} bg='#5387FF' borderRadius={'20px'}>
          <ModalHeader
            fontSize={'2xl'}
            fontWeight={'bold'}
            textAlign={'center'}
          >
            Congrats Champ
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Congratulations on taking a step towards a brighter future! At
            LucidDreams, we believe in rewarding those who are committed to
            making positive changes in their lives. We're thrilled to offer you
            a special gift for doing what most don't. Keep up the great work,
            and let us help you achieve your goals every step of the way.
          </ModalBody>
          <ModalFooter>
            <VStack py='1rem' w='100%'>
              <Text fontWeight='bold'> Scratch me for you free GIFT !!!</Text>
              <ScratchCard
                onComplete={() => {
                  setIsExploding(true);
                }}
                {...scratchsettings}
              >
                <VStack w={{base: '100%', md: '300px'}}>
                  <Image borderRadius={'15px'} src={gift} boxSize={{base: '250px', md: '300px'}} />
                  <Text fontSize={'1.1rem'} fontWeight={'bold'}>
                    Free Productivity kit worth ₹299/-
                  </Text>
                  {isExploding && <ConfettiExplosion {...mediumProps} />}
                </VStack>
              </ScratchCard>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Text
        w='100%'
        textAlign='center'
        fontWeight='extrabold'
        fontSize={{ base: '40px', md: '50px' }}
      >
        Step 2
      </Text>
      <Text
        w='100%'
        textAlign='center'
        fontWeight='extrabold'
        fontSize={{ base: '40px', md: '50px' }}
      >
        Map Your Ideal Future
      </Text>
      <Stack
        justifyContent='space-around'
        alignItems={'center'}
        direction={{ base: 'column', xl: 'row' }}
        w='100%'
      >
        <VStack w={{ base: '100%', xl: '40%' }} justifyContent='center'>
          <Text
            fontWeight='bold'
            mb='15px'
            fontSize={{ base: '20px', md: '35px' }}
          >
            Design your personalised learning framework.
          </Text>
          <Text
            color='#555555'
            textAlign='justify'
            w='100%'
            // fontWeight={'bold'}
            fontSize={{ base: '15px', md: '20px' }}
            mb='10px'
          >
            Schedule a personalised online one on one counseling session at your
            convenience - tailored to your unique needs! We specialize in
            psychometric profile analysis to help you build a customized study
            framework that brings out your strengths and passions. Our expert
            counselors will guide you through setting goals, exploring career
            options, and helping you take control of your dreams that align with
            your interests, values, and skills. Let us help you unlock your
            potential and reach your full potential. Invest in yourself and your
            future by booking a session with us today!
          </Text>
        </VStack>

        <Center
          color='black'
          w='50%'
          fontSize={{ base: '12px', md: '16px' }}
          fontWeight={'bold'}
          textAlign='center'
          h={{ base: '500px', md: '700px' }}
        >
          <Center
            borderRadius='50%'
            boxSize={{ base: '150px', md: '300px' }}
            bg='#5387FF'
            position='relative'
          >
            <Text fontSize={'1.3rem'}> 1-1 online session with our expert</Text>
            <Center
              p='5px'
              borderRadius='50%'
              boxSize={{ base: '120px', md: '200px' }}
              bg='#5387FF'
              transform={{
                base: 'translateX(140px) rotate(30deg)',
                md: 'translateX(260px) rotate(30deg)',
              }}
              transformOrigin={{ base: '-100px center', md: '-200px center' }}
              position='absolute'
            >
              <Text transform={'rotate(-30deg)'}>
                Complete refund policy (Best bet you can make)
              </Text>
            </Center>
            <Center
              p='5px'
              borderRadius='50%'
              boxSize={{ base: '100px', md: '180px' }}
              bg='#5387FF'
              transform={{
                base: 'translateX(160px) rotate(140deg)',
                md: 'translateX(280px) rotate(140deg)',
              }}
              transformOrigin={{ base: '-100px center', md: '-200px center' }}
              position='absolute'
            >
              <Text transform={'rotate(-140deg)'}>
                Detailed psychometric analysis
              </Text>
            </Center>
            <Center
              p='5px'
              borderRadius='50%'
              boxSize={{ base: '90px', md: '160px' }}
              bg='#5387FF'
              transform={{
                base: 'translateX(160px) rotate(-75deg)',
                md: 'translateX(280px) rotate(-75deg)',
              }}
              transformOrigin={{ base: '-100px center', md: '-200px center' }}
              position='absolute'
            >
              <Text transform={'rotate(75deg)'}>Find your way of learning</Text>
            </Center>
            <Center
              p='5px'
              borderRadius='50%'
              boxSize={{ base: '70px', md: '120px' }}
              bg='#5387FF'
              transform={{
                base: 'translateX(120px) rotate(-30deg)',
                md: 'translateX(260px) rotate(-30deg)',
              }}
              transformOrigin={{ base: '-100px center', md: '-200px center' }}
              position='absolute'
            >
              <Text transform={'rotate(30deg)'}>37-page report</Text>
            </Center>
            <Center
              p='5px'
              borderRadius='50%'
              boxSize={{ base: '85px', md: '150px' }}
              bg='#5387FF'
              transform={{
                base: 'translateX(150px) rotate(90deg)',
                md: 'translateX(260px) rotate(90deg)',
              }}
              transformOrigin={{ base: '-100px center', md: '-200px center' }}
              position='absolute'
            >
              <Text transform={'rotate(-90deg)'}>Discover the real you</Text>
            </Center>
            <Center
              p='5px'
              borderRadius='50%'
              boxSize={{ base: '90px', md: '175px' }}
              bg='#5387FF'
              transform={{
                base: 'translateX(160px) rotate(-130deg)',
                md: 'translateX(300px) rotate(-130deg)',
              }}
              transformOrigin={{ base: '-100px center', md: '-200px center' }}
              position='absolute'
            >
              <Text transform={'rotate(130deg)'}>
                Set realistic long-term goals
              </Text>
            </Center>
          </Center>
        </Center>
      </Stack>

      <VStack spacing='15px' pt={{ base: '35px', md: '45px', lg: '70px' }}>
        <VStack
          boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
          bg='#5387FF'
          borderRadius='15px'
          p='1.5rem'
          w={{ base: '100%', md: '25rem' }}
          h='35rem'
          justifyContent={'space-between'}
        >
          <Text
            textAlign={'center'}
            w='100%'
            fontSize='2.5rem'
            fontWeight='bold'
          >
            Lucid Plus
            <Box w='100%' h={'1px'} bg={'rgba(0,0,0,0.3)'} />
          </Text>

          <List
            fontWeight={'semibold'}
            fontSize='1.1rem'
            textAlign={'left'}
            spacing='1rem'
            w='85%'
            stylePosition='inside'
          >
            <ListItem>
              <HStack alignItems={'top'}>
                <ListIcon as={IoMdCheckmarkCircle} color='aqua' />
                <Text lineHeight={'100%'}>
                  Detailed psychometric Test with a 37-page detailed report
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack alignItems={'top'}>
                <ListIcon as={IoMdCheckmarkCircle} color='aqua' />
                <Text lineHeight={'100%'}>
                  One-on-one session with our expert counsellors
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack alignItems={'top'}>
                <ListIcon as={IoMdCheckmarkCircle} color='aqua' />
                <Text lineHeight={'100%'}>
                  Develop a unique learning framework.
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack alignItems={'top'}>
                <ListIcon as={IoMdCheckmarkCircle} color='aqua' />
                <Text lineHeight={'100%'}>
                  Get a personal mentor and enjoy 2-month access Lucid
                  Plus Community
                </Text>
              </HStack>
            </ListItem>
            <ListItem>
              <HStack alignItems={'top'}>
                <ListIcon as={IoMdCheckmarkCircle} color='aqua' />
                <Text lineHeight={'100%'}>
                  FREE productivity kit worth 299/-
                </Text>
              </HStack>
            </ListItem>
          </List>
          <Button
            // w={{ base: '300px', md: '500px' }}
            h='50px'
            bg='black'
            color='#5387FF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            fontSize={{ base: '15px', md: '20px' }}
            p={{ base: '10px', md: '20px' }}
            _hover={{}}
            animation={attractAnim}
            onClick={() => {
              if (localStorage.getItem('isLogin') === 'true') {
                setProduct('4');
                navigate('/checkout');
              } else {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
                setShowLogin(true);
              }
            }}
            // isDisabled={watched ? false : true}
          >
            <del>₹3999</del> <Text px='5px'>₹ 3499 /-</Text>
          </Button>
        </VStack>

        <Text
          pt='2rem'
          color='black'
          fontSize={{ base: '30px', md: '40px', lg: '50px' }}
          fontWeight='bold'
        >
          The Lucid Community
        </Text>

        <HStack pt='2rem' spacing={{ base: '30px', md: '200px' }}>
          <VStack>
            <Center
              boxSize={{ base: '150px', md: '250px' }}
              borderRadius={'200px'}
              bg='#5387FF'
            >
              <Image src={comm1} boxSize={{ base: '110px', md: '200px' }} />{' '}
            </Center>
            <Text fontWeight='semibold' fontSize={'1.2rem'}>
              General Community
            </Text>
          </VStack>
          <VStack>
            <Center
              boxSize={{ base: '150px', md: '250px' }}
              borderRadius={'200px'}
              bg='#5387FF'
            >
              <Image src={comm2} boxSize={{ base: '110px', md: '200px' }} />{' '}
            </Center>
            <Text fontWeight='semibold' fontSize={'1.2rem'}>
              Profile-Based Community
            </Text>
          </VStack>
        </HStack>
      </VStack>

      <VStack w='100%' gap='30px'>
        <Text
          w='100%'
          fontWeight='bold'
          fontSize={{ base: '20px', md: '40px' }}
        >
          General Community
        </Text>
        <SimpleGrid columns={{ base: '1', md: '2' }} spacing={'1rem'}>
          <Textbox img={gc1}>
            Get a personal mentor to help you stay motivated, clear your doubts
            and help you with your homework.
          </Textbox>
          <Textbox img={gc2}>
            Make learning interesting by joining daily study sessions, clear
            your doubts.
          </Textbox>
          <Textbox img={gc3}>
            Say Goodbye to tuition classes and learn the Lucid way.
          </Textbox>
          <Textbox img={gc4}>
            Holistic development is fun and effortless with games, activities,
            and much more.
          </Textbox>
          <Textbox img={gc5}>
            Monthly Parent - Mentor meetings to track your progress.
          </Textbox>
          <Textbox img={gc6}>
            No more hard work when you can work smart.
          </Textbox>
          <Textbox img={gc7}>
            Develop critical social skills such as communication, teamwork, and
            leadership.
          </Textbox>
        </SimpleGrid>
        <VStack gap='10px'></VStack>
        {/* <Box
            w={{ base: '100%', md: '45%' }}
            // mt={{ base: '50px', md: '50px' }}
            // ml={{ base: '', md: '100px' }}
            display='flex'
            justifyContent={{ base: 'center', md: 'right' }}
            alignItems='center'
            // bg='blue'
          >
            <Image
              w='85%'
              // transform='rotateZ(15deg)'
              src={comm1big}
              borderRadius='45%'
            />
          </Box> */}
      </VStack>

      <Text
        mt='100px'
        w='100%'
        fontWeight='bold'
        fontSize={{ base: '20px', md: '50px' }}
        textAlign={'center'}
      >
        7 - Fun Fundamentals
      </Text>

      <Center
        w={{ base: '97%', md: '70%' }}
        // bg='blue'
        overflowX={'hidden'}
      >
        <Splide
          options={{
            type: 'loop',
            autoplay: true,
            interval: 5000,
            arrows: true,
            pagination: true,
            width: '100%',
            // direction: 'ttb',
            height: '500px',
            // heightRatio: '0.1',
          }}
          hasTrack={false}
        >
          <SplideTrack>
            {events.map((item, index) => (
              <SplideSlide>
                <Box w='100%' minH='100px'>
                  <Event data={events[index]} />
                </Box>
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide>
      </Center>

      <VStack w='100%' gap='30px'>
        <Text
          w='100%'
          fontWeight='bold'
          fontSize={{ base: '20px', md: '40px' }}
          // textAlign={'end'}
        >
          Profile-Based Community
        </Text>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems={'center'}
          w='100%'
        >
          <VStack w={{ base: '100%', md: '55%' }} gap='20px'>
            <Textbox img={pc1}>
              Develop skills with the best resources through interactive
              activities, games, and much more!
            </Textbox>
            <Textbox img={pc2}>
              Get access to industry experts and professionals who can provide
              valuable insights and guidance related to their interests and
              career goals.
            </Textbox>
            <Textbox img={pc3}>
              Engage in meaningful conversations, collaborate on projects, and
              participate in activities that align with your profile and
              interests.{' '}
            </Textbox>
            <Textbox img={pc4}>
              Be part of a positive learning environment that encourages
              exploration, innovation, and creativity.
            </Textbox>
          </VStack>
          <Center w='45%'>
            <Splide
              options={{
                type: 'loop',
                perPage: 3,
                perMove: 1,
                autoplay: true,
                interval: 2000,
                arrows: false,
                pagination: false,
                direction: 'ttb',
                height: '300px',
                // heightRatio: '1',
              }}
              hasTrack={false}
            >
              <SplideTrack>
                {jobs.map(job => (
                  <SplideSlide>
                    <HStack
                      boxShadow='-1px 0px 5px #0000001a, 1px 0px 5px #0000001a'
                      borderRadius='0.25rem'
                      w={{ base: '250px', md: '300px' }}
                      h={{ base: '50px', md: '60px' }}
                      mx='10px'
                      my='0px'
                      p='10px'
                      display='flex'
                      justifyContent='center'
                      position='relative'
                      overflow='hidden'
                      bg='white'
                    >
                      {/* <Icon
                      position='absolute'
                      top='-60px'
                      left='-60px'
                      boxSize='150px'
                      color='#0000004b'
                      as={job.icon}
                    /> */}
                      <Text
                        // pl={{ base: '80px', md: '30px' }}
                        color='black'
                        fontSize={{ base: '20px', md: '30px' }}
                        // fontSize={splideFont}
                        fontWeight='bold'
                      >
                        {job.title}
                      </Text>
                    </HStack>
                  </SplideSlide>
                ))}
              </SplideTrack>
            </Splide>
          </Center>
        </Stack>
      </VStack>

      <Stack mt='100px' direction={{ base: 'column', md: 'row' }} w='100%'>
        <VStack w='100%'>
          <Image
            boxSize={{ base: '250px', md: '200px', lg: '250px' }}
            src={prof2}
          ></Image>
          <Text>Weekly Profile based-games with Attractive Prizes</Text>
        </VStack>
        <VStack w='100%'>
          <Image
            boxSize={{ base: '250px', md: '200px', lg: '250px' }}
            src={prof1}
          ></Image>
          <Text>Meet the Industry Expert</Text>
        </VStack>
        <VStack w='100%'>
          <Image
            boxSize={{ base: '250px', md: '200px', lg: '250px' }}
            src={prof3}
          ></Image>
          <Text>Hands-on Workshops and Activities</Text>
        </VStack>
      </Stack>

      <Text
        mt='100px'
        w='100%'
        fontWeight='bold'
        fontSize={{ base: '20px', md: '35px' }}
        textAlign={'center'}
      >
        FAQ !
      </Text>
      <Accordion w={'100%'} defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton _hover={{}} borderRadius='10px' bg={'#5387FF'}>
              <Box w='100%' as='span' flex='1' textAlign='left'>
                Do I need to install a separate app to join the community?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            No, We use Whatsapp and Discord as our mediums of communication.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _hover={{}} borderRadius='10px' bg='#5387FF'>
              <Box w='100%' as='span' flex='1' textAlign='left'>
                Can I avail myself of all these features with a one-time
                payment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Yes, you get detailed psychometric analysis, a session, and 2-month
            access to the Lucid community.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _hover={{}} borderRadius='10px' bg='#5387FF'>
              <Box as='span' flex='1' textAlign='left'>
                What if I don’t like the product?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            There is a complete refund policy after the duration of 2 months,
            check Refund Policy for more info.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <HStack
        w={{ base: '90vw', md: '80vw', lg: '60vw' }}
        justifyContent='center'
        gap='10px'
        // bg='red'
        // position='relative'
      >
        <Button
          // w={{ base: '300px', md: '500px' }}
          h='50px'
          // border='1px solid #109dab'
          bg='black'
          color='#5387FF'
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderRadius='15px'
          fontSize={{ base: '15px', md: '20px' }}
          p={{ base: '10px', md: '20px' }}
          _hover={{}}
          // animation={attractAnim}
          onClick={() => {
            if (localStorage.getItem('isLogin') === 'true') {
              navigate('/test/basictest');
            } else {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0;
              setShowLogin(true);
            }
          }}
          // isDisabled={watched ? false : true}
        >
          Take the FREE Test
        </Button>

        <Button
          // w={{ base: '300px', md: '500px' }}
          h='50px'
          bg='#5387FF'
          display='flex'
          justifyContent='center'
          alignItems='center'
          borderRadius='15px'
          fontSize={{ base: '15px', md: '20px' }}
          p={{ base: '10px', md: '20px' }}
          _hover={{}}
          animation={attractAnim}
          onClick={() => {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
          }}
          // isDisabled={watched ? false : true}
        >
          Take the Lucid Test
        </Button>
      </HStack>
    </Box>
  );
}

function Event(props) {
  return (
    <Box
      h={'500px'}
      pt='10px'
      gap={'10px'}
      w='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='top'
    >
      <Image
        src={props.data.img}
        boxSize={'110px'}
        p='10px'
        borderRadius={'10px'}
        // border='1px solid #109dab'
        boxShadow='-5px 5px 5px #0000001a, 5px -5px 10px #dddddd'
        // borderRadius='50px'
      />
      <VStack>
        <VStack
          p='15px'
          borderRadius={'10px'}
          w={{ base: '95%', md: '75%' }}
          bg='#5387FF'
        >
          <Text
            textAlign={'center'}
            fontWeight='extrabold'
            color='black'
            fontSize={{ base: '20px', md: '30px' }}
          >
            {props.data.title}
          </Text>
          <Text
            w={'100%'}
            textAlign='justify'
            fontSize={{ base: '15px', md: '20px' }}
            color='whiteAlpha.800'
          >
            {props.data.description}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}
