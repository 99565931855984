import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  Stack,
  VStack,
  Heading,
  Flex,
  IconButton,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';

export default function Privacy() {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <VStack align='flex-start' pb='5rem' w={{ base: '90%', md: '80%' }}>
      <Heading w='100%' textAlign='center' size='2xl' py='5rem'>
        Privacy Policy
      </Heading>
      <Text>
        LucidDreams. respects your privacy. This Privacy Policy outlines the
        information we collect and how we use it. This Policy applies solely to
        information collected through this Site and not through other sources
      </Text>
      <Heading size='lg' py='3rem'>
        COLLECTION OF YOUR PERSONAL INFORMATION
      </Heading>
      <Text>
        If you browse through this site without providing us with any personal
        information, we will gather and store some information about your visit,
        such as IP address, type of browser and operating system used, date and
        time you access our site, pages you visit, and if you linked to our
        website from another website, the address of that website. This
        information will not identify you personally and will not be linked back
        to you.
      </Text>
      <Text>
        Personal information (i.e. information which may identify you in some
        way, such as your name, address, age, other contact details such as
        telephone number, email address etc. and passwords) may be collected
        through this website only when you voluntarily submit it. This may be
        when you register on the website, request information, submit comments
        or participate in some game, promotion, survey or other feature of the
        website. Providing personal information is up to you. If you do not want
        your personal information collected, please do not submit it. If you
        choose not to give personal information to us, we may not be able to
        provide you with access to some or all of the pages on this Site or our
        other products and services.
      </Text>
      <Text>
        Occasionally, we may ask you for personal information about other people
        - for example, your family or other authorized representatives. If you
        give us this information, we rely on you to tell those people that you
        are giving their personal information to us and to make them aware of
        this Privacy Policy.
      </Text>
      <Heading size='lg' py='3rem'>
        USE OF YOUR PERSONAL INFORMATION
      </Heading>
      <Text>Your personal information may be used as follows:</Text>
      <UnorderedList>
        <ListItem>
          To respond to your questions and requests, to provide you with access
          to certain areas and features and to communicate with you about your
          activities on this website.
        </ListItem>
        <ListItem>
          To share it with our Related Parties as required to perform functions
          on our behalf in connection with the Site (such as delivery of
          merchandise, administration of the website or promotions or other
          features on it, marketing, data analysis or customer services). To do
          so, it may be necessary for us to transmit your personal information
          to outside the applicable Jurisdiction and you agree to this transfer.
          Further use, or disclosure, of the information by them for other
          purposes is not permitted.
        </ListItem>
        <ListItem>
          To provide you with product information or promotional and other
          offers from us or our Related Parties *.
        </ListItem>
        <ListItem>
          * However, an "opt-out" mechanism will provide you the opportunity to
          indicate that you do not want us to send you such further
          communications, and if you "opt-out" we will not send you any.
        </ListItem>
        <ListItem>
          * Either way, opting-in or opting-out will be up to you and, in
          addition, if you do not want us to use your personal information for a
          particular purpose or disclose it to a third party, you may "opt out"
          at any time by contacting us as provided below.
        </ListItem>
        <ListItem>
          * Specific opt-outs may also be provided along with the communications
          to you.
        </ListItem>
        <ListItem>
          * Please note though, by opting-out, you may not be able to
          participate in certain activities on the Site.
        </ListItem>
        <ListItem>
          For other purposes set out when your information is collected or in
          any additional terms and conditions applicable to the particular
          feature of the Site. For disclosures required by law, regulation or
          court order.
        </ListItem>
        <ListItem>
          For disclosures required by law, regulation or court order.
        </ListItem>
        <ListItem>
          For the purpose of or in connection with legal proceedings or
          necessary for establishing, defending or exercising legal rights.
        </ListItem>
        <ListItem>
          In an emergency to protect the health or safety of Site users or the
          general public or in the interests of national security.
        </ListItem>
      </UnorderedList>
      <Text>
        Except as provided in herein, we will not provide any of your personal
        information to any third parties without your specific consent.
      </Text>
      <Heading size='lg' py='3rem'>
        Children
      </Heading>
      <Text>
        Children's privacy protection is taken seriously. We operate this
        website in compliance with all applicable laws in the applicable
        Jurisdiction. Children under the age referred to below for the
        appropriate Jurisdiction for the Site should have a parent/guardian's
        consent before providing any personal information to the website. We
        will not, as provided by applicable law, require or request children
        under this age to provide more personal information than is reasonably
        necessary to participate in the applicable activity on the Site. If we
        determine upon collection that a user is under this age, we will not use
        or maintain his/her personal information without the parent/guardian's
        consent. Without such consent, though, the child may not be able to
        participate in certain activities. However, in certain circumstances, we
        may maintain and use such information (in accordance with the rest of
        this Policy and applicable law) in order to notify and obtain consent
        from the parent/guardian and for certain safety, security, liability and
        other purposes permitted under applicable law. A parent/ guardian can
        review, remove, change or refuse further collection or use of their
        child's personal information by contacting us as provided below (include
        child's name, address and e-mail address). Site's
        Jurisdiction/Applicable Age: United States, Australia & New Zealand:
        Under 13 years of age. Other Jurisdictions: Under 8 years of age.
      </Text>
      <Heading size='lg' py='3rem'>
        COLLECTION OF ADDITIONAL INFORMATION
      </Heading>
      <Text>
        Occasionally, when you connect to our Site we may use "cookie"
        technology and IP addresses to automatically collect certain technical
        non-personally identifiable information. This might include your type of
        Internet Browser or computer, the domain name of your Internet Service
        Provider, number of page visits and the character and duration of such
        visits. We also track the number of visitors to our website in an
        aggregate form. Personally identifiable information is not extracted in
        this process. This information helps us keep our website fresh and
        interesting to our visitors and tailor content to a visitor's interests.
        We may disclose certain non-personally identifiable information to
        potential partners, advertisers (who may use this information to provide
        advertisements tailored to your interests) or other third parties, but
        we will not disclose any personally identifiable information, except as
        provided in this Policy. Also, we may allow third parties that serve
        advertisements on our Site (including our promotion partners) to place
        and access cookies on your computer, but they will not be able to access
        our cookies. The use of cookies by such third parties are not subject to
        our Policy, but are subject to their own respective privacy policies.
        You may set your computer browser to disable cookies or to alert you
        when cookies are being sent. If you disable cookies, we may be unable to
        optimise your visit to our website and certain features may not be
        available.
      </Text>
      <Heading size='lg' py='3rem'>
        DISCLOSURE
      </Heading>
      <Text>
        We may disclose information about you to any of our employees, officers,
        agents, suppliers or subcontractors insofar as reasonably necessary for
        the purposes as set out in this privacy policy.
      </Text>
      <Text>In addition, we may disclose your personal information:</Text>
      <UnorderedList>
        <ListItem>to the extent that we are required to do so by law;</ListItem>
        <ListItem>
          in connection with any legal proceedings or prospective legal
          proceeding;
        </ListItem>
        <ListItem>
          in order to establish, exercise or defend our legal rights (including
          providing information to others for the purposes of fraud prevention
          and reducing credit risk);
        </ListItem>
        <ListItem>
          to the purchaser (or prospective purchaser) of any business or asset
          which we are (or are contemplating) selling; and
        </ListItem>
        <ListItem>
          {' '}
          to any person who we reasonably believe may apply to a court or other
          competent authority for disclosure of that personal information where,
          in our reasonable opinion, such court or authority would be reasonably
          likely to order disclosure of that personal information.
        </ListItem>
      </UnorderedList>
      <Text>
        Except as provided in this privacy policy, we will not provide your
        information to third parties.
      </Text>
      <Heading size='lg' py='3rem'>
        INTERNATIONAL DATA TRANSFERS
      </Heading>
      <Text>
        Information that we collect may be stored and processed in and
        transferred between any of the countries in which we operate in order to
        enable us to use the information in accordance with this privacy policy.
      </Text>
      <Text>
        Information which you provide may be transferred to countries which do
        not have data protection laws.
      </Text>
      <Text>
        You expressly agree to such transfers of personal information.
      </Text>
      <Heading size='lg' py='3rem'>
        SECURITY OF YOUR INFORMATION
      </Heading>
      <Text>
        We safeguard your privacy using known security standards and procedures
        and comply with applicable privacy laws. Our websites combine
        industry-approved physical, electronic and procedural safeguards to
        ensure that your information is well protected through it's life cycle
        in our infrastructure.
      </Text>
      <Text>
        However, we cannot guarantee the security of such information. To the
        fullest extent permitted by law, we disclaim all liability and
        responsibility for any Damages you may suffer due to any loss,
        unauthorized access, misuse or alteration of any information you submit
        to this website.
      </Text>
      <Heading size='lg' py='3rem'>
        OTHER SITES
      </Heading>
      <Text>
        This Site may contain links to other web sites that we do not own or
        operate. We do not control, recommend or endorse and are not responsible
        for these sites or their content, products, services or privacy
        policies. Downloading material from certain sites may risk infringing
        intellectual property rights or introducing viruses into your system.
        You should note when you leave this Site and read the privacy policies
        and terms of these other sites. You should also independently assess the
        authenticity of any website which appears or claims that it is one of
        our sites (including those linked to through an email).
      </Text>
      <Heading size='lg' py='3rem'>
        OTHER
      </Heading>
      <Heading size='md' py='3rem'>
        Jurisdiction
      </Heading>
      <Text>
        We control and operate the Site from the Company Location (regardless of
        where hosting servers are located). All matters relating to the Site are
        governed by Indian Law, without reference to conflict or choice of law
        principles. You agree that jurisdiction and venue for any legal
        proceeding relating to the Site shall be in appropriate courts located
        in Kozhikode, India. We do not warrant or imply that the Site or
        materials on it are appropriate for use outside of the above Country.
        The information set forth in this Site concerning any products or
        services is applicable only in the Jurisdiction, and these products or
        services may not be available in all locations. If you are located
        outside of this Country, you are solely responsible for compliance with
        any applicable local laws.
      </Text>
      <Heading size='md' py='3rem'>
        Transfer of assets
      </Heading>
      <Text>
        During the course of our business, we may sell or purchase assets. If
        another entity acquires us or all or substantially all of our assets,
        personally and non-personally identifiable information we have collected
        about the users of the Site may be transferred to such entity and used
        by that entity for the purposes set out in this Privacy Policy. Also, if
        any bankruptcy or reorganization proceeding is brought by or against us,
        such information may be considered an asset of ours and may be sold or
        transferred to third parties.
      </Text>
      <Heading size='md' py='3rem'>
        Severability
      </Heading>
      <Text>
        If for any reason, any provision herein is found void or unenforceable,
        it will be severed to the extent void or unenforceable and the remaining
        provisions will continue in full force and effect.
      </Text>
      <Heading size='md' py='3rem'>
        Definitions/Interpretation
      </Heading>
      <Text>As used herein:</Text>
      <UnorderedList>
        <ListItem>
          "Damages" means any and all direct, special, indirect, consequential
          or punitive loss or other damages of any kind (whether in contract,
          including fundamental breach, tort, including negligence, or
          otherwise)
        </ListItem>

        <ListItem>"including" means "including, but not limited to"</ListItem>
        <ListItem>
          "materials sent to the Site (and "materials you send to the Site" and
          other like terms) means anything emailed, uploaded, posted or
          otherwise transmitted or sent to the Site (whether information, text,
          material, data or code or other) by you or another user.
        </ListItem>
        <ListItem>
          "Related Parties" means all of our parent, subsidiary and affiliated
          companies, Site Developers and other promotional partners.
        </ListItem>
        <ListItem>
          "Site Developer" means any party involved in creating, producing,
          delivering or maintaining the Site.
        </ListItem>
        <ListItem>
          "use of this Site" (and "using" and other like terms) means "any and
          all use of the Site of any kind whatsoever, including access to,
          browsing of, reviewing, posting of, transmitting, reviewing,
          downloading, and other using the Site or any material on the Site."
        </ListItem>
      </UnorderedList>
      <Heading size='md' py='3rem'>
        Changes ACCESSABILITY STATEMENT:
      </Heading>
      <Text>
        Please note, we may change information on this Site and/or this Policy,
        at any time without notice. You should regularly review this Policy for
        any changes. Any changes will be effective immediately upon the posting
        of the revised Policy on this Site. By continuing to use the Site, you
        agree to any changes, and by providing continued access to the Site we
        are providing you consideration for agreement to such changes.
      </Text>
      <Heading size='md' py='3rem'>
        ACCESSABILITY STATEMENT:
      </Heading>
      <Text>
        Many Internet users with disabilities find web sites difficult or even
        impossible to use simply because of the way they are designed. We are
        working hard at LUCIDDREAMS. to provide access to information that is
        available to all our consumers, and this goes for the website, too. We
        are aware of the types of adaptive technology used by people with
        disabilities and are redesigning pages across our portfolio of websites
        to ensure compatibility with as many of these systems as possible.
        Changes include keeping text clear, formatting layouts and tables, and
        making sure links adequately describe where they go ('click here' does
        not mean much in isolation).
      </Text>
      <Text>
        We are rebuilding our websites to meet the Web Accessibility Initiative
        (WAI) Content Accessibility Guidelines set by the World Wide Web
        Consortium (W3C) and we aim, wherever possible, to ensure we conform to
        'Double-A' standards.
      </Text>
      <Text>{`If you come across a part of our site that is difficult to use please tell us about it. We intend to keep improving our accessibility – so to help us do it, send us your comments.`}</Text>
      <Heading size='xl' py='3rem'>
        CONTACT US
      </Heading>
      <Text>
        If you have any questions or comments regarding this Site, or wish to
        verify, correct, access or update any of your personal information
        collected through this Site, contact us at{' '}
        <Link href={'mailto:info@luciddreams.com'}>info@luciddreams.com</Link>
      </Text>
    </VStack>
  );
}
