import React, { useEffect, useState, useContext } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Image,
} from '@chakra-ui/react';
import { GlobalProvider } from './Context/GlobalContext';
import Login from './pages/Login';
import Top from './Components/Top';
import Footer from './Components/Footer';
import Landing from './pages/Landing';
import Community from './pages/Community';
import Services from './pages/Services';
import Test from './pages/Test';
import Profile from './pages/Profile';
import BasicTest from './pages/BasicTest';
import './App.css';
import Accessories from './pages/Accessories';
import Aboutus from './pages/Aboutus';
import Soon from './pages/Soon';
import Term from './pages/TermsandConditions';
import Privacy from './pages/Privacy';
import Refund from './pages/Refund';
import Checkout from './pages/Checkout';
import Contact from './pages/Contact';

function App() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <GlobalProvider>
        <VStack
          maxW='100vw'
          // overflowX={'hidden'}
          fontFamily='Pretendard'
          color='black'
          bg='#ededed'
          position='relative'
          pt={{ base: '30px', md: '40px' }}
        >
          <Router>
            <Top />
            <Login />
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/community' element={<Community />} />
              <Route path='/services' element={<Services />} />
              <Route path='/test' element={<Test />} />
              <Route path='/accessories' element={<Soon />} />
              <Route path='/aboutus' element={<Aboutus />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/test/basictest' element={<BasicTest />} />
              <Route path='/contactus' element={<Contact />} />
              <Route path='/blog' element={<Soon />} />
              <Route path='/helpcenter' element={<Soon />} />
              <Route path='/termsandconditions' element={<Term />} />
              <Route path='/privacypolicy' element={<Privacy />} />
              <Route path='/refundpolicy' element={<Refund />} />
              <Route path='/checkout' element={<Checkout />} />
            </Routes>
            <VStack justifyContent='center' w='100%' mt='50px' bg='black'>
              <Footer />
            </VStack>
          </Router>
        </VStack>
      </GlobalProvider>
    </ChakraProvider>
  );
}

export default App;
