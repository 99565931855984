import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  Divider,
  Link,
  Stack,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  Heading,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import img from '../assets/bookpng.png';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { FaShoppingCart } from 'react-icons/fa';
import Top from '../Components/Top';

export default function Accessories() {
  const products = [
    {
      name: 'Personal Diary',
      img: img,
      description:
        'Diary to make sketches, keep progress and do some other sit heres some. More words because yes.',
    },
    {
      name: 'Holistic Toolkit',
      img: img,
      description:
        'Diary to make sketches, keep progress and do some other sit heres some. More words because yes.',
    },
  ];
  return (
    <VStack
      w='100vw'
      pt='50px'
      spacing={{ base: '30px', md: '80px' }}
      h='100vh'
    >
      {/* <Top /> */}
      <Heading size={{ base: 'xl', md: '2xl' }}>
        Gear up your Journey !!
      </Heading>
      {products.map(product => (
        <ProductCard product={product} />
      ))}
      <Button
        size={'lg'}
        bg='linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'
        borderRadius='15px'
      >
        Place Order
      </Button>
    </VStack>
  );
}

const ProductCard = props => {
  const [amt, setAmt] = useState(0);
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      alignItems={'center'}
      boxShadow='5px 5px 30px rgba(0, 0, 0, 0.102), -5px -5px 30px rgba(0, 0, 0, 0.102)'
      borderRadius={'10px'}
      //   bg='violet'
      color='black'
      spacing={'20px'}
      p='10px'
      w={{ base: '300px', md: '600px' }}
    >
      <Image w='200px' src={props.product.img} objectFit={'cover'} />

      <VStack align='start'>
        <Heading size='md'>{props.product.name}</Heading>
        <Text>{props.product.description}</Text>
        <HStack w='100%' justifyContent={'space-between'}>
          <HStack
            borderRadius={'5px'}
            border={'1px solid #0000006e'}
            spacing={0}
          >
            <IconButton
              icon={<MinusIcon />}
              size='sm'
              onClick={() => {
                setAmt(Math.max(0, amt - 1));
              }}
            />
            <Text textAlign={'center'} w={'25px'}>
              {amt}
            </Text>
            <IconButton
              icon={<AddIcon />}
              size='sm'
              onClick={() => {
                setAmt(amt + 1);
              }}
            />
          </HStack>
          <HStack>
            <Text>{amt}</Text>
            <Icon boxSize={7} as={FaShoppingCart} />
          </HStack>
        </HStack>
      </VStack>
    </Stack>
  );
};
