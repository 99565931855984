import React, { useEffect, useContext, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Divider,
  Link,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Center,
  Stack,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import './Community.css';
import { GlobalContext } from '../Context/GlobalContext';
import comm1 from '../assets/comm1.png';
import comm2 from '../assets/comm2.png';
import comm1big from '../assets/comm1big.png';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { FaPlusCircle } from 'react-icons/fa';
import Top from '../Components/Top';
import { Textbox } from './Test';
import prof1 from '../assets/profile-1.svg';
import prof2 from '../assets/profile-2.svg';
import prof3 from '../assets/profile-3.svg';
import plan1 from '../assets/plan-1.svg';
import plan2 from '../assets/plan-2.svg';
import plan3 from '../assets/plan-3.svg';
import gc1 from '../assets/gc1.png';
import gc2 from '../assets/gc2.png';
import gc3 from '../assets/gc3.png';
import gc4 from '../assets/gc4.png';
import gc5 from '../assets/gc5.png';
import gc6 from '../assets/gc6.png';
import gc7 from '../assets/gc7.png';
import pc1 from '../assets/pc1.png';
import pc2 from '../assets/pc2.png';
import pc3 from '../assets/pc3.png';
import pc4 from '../assets/pc4.png';
import { NavLink, useNavigate } from 'react-router-dom';

export default function Community() {
  const pricing = [
    {
      title: 'Trimester Plan',
      price: '534',
      duration: '3-months',
      time: 'per month',
      total: '1600',
      features: [
        'Full feature access 1',
        'Full feature access 2',
        'Full feature access 3',
        'Full feature access 4',
        'Full feature access 5',
      ],
      img: plan1,
      product: '1',
    },
    {
      title: 'Mid-Year Pass',
      price: '500',
      duration: '6-months',
      time: 'per month',
      total: '3000',
      features: [
        'Full feature access 1',
        'Full feature access 2',
        'Full feature access 3',
        'Full feature access 4',
        'Full feature access 5',
        'Full feature access 6',
      ],
      img: plan2,
      product: '2',
    },
    {
      title: 'One-Year Sprint',
      price: '418',
      duration: '12-months',
      time: 'per month',
      total: '5000',
      features: [
        'Full feature access 1',
        'Full feature access 2',
        'Full feature access 3',
        'Full feature access 4',
        'Full feature access 5',
      ],
      img: plan3,
      product: '3',
    },
  ];

  const jobs = [
    {
      title: 'Doctor',
      icon: FaPlusCircle,
    },
    {
      title: 'Software Developer',
      icon: FaPlusCircle,
    },
    {
      title: 'Scientist',
      icon: FaPlusCircle,
    },
    {
      title: 'Investment Banker',
      icon: FaPlusCircle,
    },
    {
      title: 'Data Scientist',
      icon: FaPlusCircle,
    },
    {
      title: 'Architect',
      icon: FaPlusCircle,
    },
    {
      title: 'Police ',
      icon: FaPlusCircle,
    },
    {
      title: 'Civil Service',
      icon: FaPlusCircle,
    },
  ];

  function handleClick(comm) {
    var element = document.getElementById(comm);
    var headerOffset = 0;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  const { showLogin, setShowLogin, isLogin, setIsLogin, product, setProduct } =
    useContext(GlobalContext);

  const toast = useToast();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);

  const navigate = useNavigate();

  return (
    <Box
      w='100%'
      minH='100vh'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      bg='#ededed'
      pt='50px'
    >
      {/* <Top /> */}
      <Text
        w='100%'
        textAlign='center'
        fontWeight='extrabold'
        fontSize={{ base: '40px', md: '50px' }}
        pb='20px'
      >
        Step 3
      </Text>
      <Text
        w='90%'
        mb={{ base: '50px', md: '' }}
        color='black'
        fontSize={{ base: '40px', md: '50px' }}
        fontWeight='bold'
        textAlign='center'
      >
        Join the Lucid Community
      </Text>

      <VStack
        spacing={{ base: '50px', md: '100px' }}
        w={{ base: '90%', md: '70%' }}
      >
        <Stack
          justifyContent='space-around'
          alignItems={'center'}
          direction={{ base: 'column', xl: 'row' }}
          w='100%'
        >
          <VStack w={{ base: '100%', xl: '40%' }} justifyContent='center'>
            <Text
              fontWeight='bold'
              mb='15px'
              fontSize={{ base: '20px', md: '35px' }}
            >
              Design your personalised learning framework.
            </Text>
            <Text
              color='#555555'
              textAlign='justify'
              w='100%'
              // fontWeight={'bold'}
              fontSize={{ base: '15px', md: '20px' }}
              mb='10px'
            >
              Schedule a personalised online one on one counseling session at
              your convenience - tailored to your unique needs! We specialize in
              psychometric profile analysis to help you build a customized study
              framework that brings out your strengths and passions. Our expert
              counselors will guide you through setting goals, exploring career
              options, and helping you take control of your dreams that align
              with your interests, values, and skills. Let us help you unlock
              your potential and reach your full potential. Invest in yourself
              and your future by booking a session with us today!
            </Text>
          </VStack>

          <Center
            color='black'
            w='50%'
            fontSize={{ base: '12px', md: '16px' }}
            fontWeight={'bold'}
            textAlign='center'
            h={{ base: '500px', md: '700px' }}
          >
            <Center
              borderRadius='50%'
              boxSize={{ base: '150px', md: '300px' }}
              bg='#5387FF'
              position='relative'
            >
              <Text fontSize={'1.3rem'}>
                {' '}
                1-1 online session with our expert
              </Text>
              <Center
                p='5px'
                borderRadius='50%'
                boxSize={{ base: '120px', md: '200px' }}
                bg='#5387FF'
                transform={{
                  base: 'translateX(140px) rotate(30deg)',
                  md: 'translateX(260px) rotate(30deg)',
                }}
                transformOrigin={{ base: '-100px center', md: '-200px center' }}
                position='absolute'
              >
                <Text transform={'rotate(-30deg)'}>
                  Complete refund policy (Best bet you can make)
                </Text>
              </Center>
              <Center
                p='5px'
                borderRadius='50%'
                boxSize={{ base: '100px', md: '180px' }}
                bg='#5387FF'
                transform={{
                  base: 'translateX(160px) rotate(140deg)',
                  md: 'translateX(280px) rotate(140deg)',
                }}
                transformOrigin={{ base: '-100px center', md: '-200px center' }}
                position='absolute'
              >
                <Text transform={'rotate(-140deg)'}>
                  Detailed psychometric analysis
                </Text>
              </Center>
              <Center
                p='5px'
                borderRadius='50%'
                boxSize={{ base: '90px', md: '160px' }}
                bg='#5387FF'
                transform={{
                  base: 'translateX(160px) rotate(-75deg)',
                  md: 'translateX(280px) rotate(-75deg)',
                }}
                transformOrigin={{ base: '-100px center', md: '-200px center' }}
                position='absolute'
              >
                <Text transform={'rotate(75deg)'}>
                  Find your way of learning
                </Text>
              </Center>
              <Center
                p='5px'
                borderRadius='50%'
                boxSize={{ base: '70px', md: '120px' }}
                bg='#5387FF'
                transform={{
                  base: 'translateX(120px) rotate(-30deg)',
                  md: 'translateX(260px) rotate(-30deg)',
                }}
                transformOrigin={{ base: '-100px center', md: '-200px center' }}
                position='absolute'
              >
                <Text transform={'rotate(30deg)'}>37-page report</Text>
              </Center>
              <Center
                p='5px'
                borderRadius='50%'
                boxSize={{ base: '85px', md: '150px' }}
                bg='#5387FF'
                transform={{
                  base: 'translateX(150px) rotate(90deg)',
                  md: 'translateX(260px) rotate(90deg)',
                }}
                transformOrigin={{ base: '-100px center', md: '-200px center' }}
                position='absolute'
              >
                <Text transform={'rotate(-90deg)'}>Discover the real you</Text>
              </Center>
              <Center
                p='5px'
                borderRadius='50%'
                boxSize={{ base: '90px', md: '175px' }}
                bg='#5387FF'
                transform={{
                  base: 'translateX(160px) rotate(-130deg)',
                  md: 'translateX(300px) rotate(-130deg)',
                }}
                transformOrigin={{ base: '-100px center', md: '-200px center' }}
                position='absolute'
              >
                <Text transform={'rotate(130deg)'}>
                  Set realistic long-term goals
                </Text>
              </Center>
            </Center>
          </Center>
        </Stack>

        <VStack w='100%' gap='30px'>
          <Text
            w='100%'
            fontWeight='bold'
            fontSize={{ base: '20px', md: '30px' }}
          >
            General Community
          </Text>
          <SimpleGrid columns={{ base: '1', md: '2' }} spacing={'1rem'}>
            <Textbox img={gc1}>
              Get a personal mentor to help you stay motivated, clear your
              doubts and help you with your homework.
            </Textbox>
            <Textbox img={gc2}>
              Make learning interesting by joining daily study sessions, clear
              your doubts.
            </Textbox>
            <Textbox img={gc3}>
              Say Goodbye to tuition classes and learn the Lucid way.
            </Textbox>
            <Textbox img={gc4}>
              Holistic development is fun and effortless with games, activities,
              and much more.
            </Textbox>
            <Textbox img={gc5}>
              Monthly Parent - Mentor meetings to track your progress.
            </Textbox>
            <Textbox img={gc6}>
              No more hard work when you can work smart.
            </Textbox>
            <Textbox img={gc7}>
              Develop critical social skills such as communication, teamwork,
              and leadership.
            </Textbox>
          </SimpleGrid>
          <VStack gap='10px'></VStack>
          {/* <Box
            w={{ base: '100%', md: '45%' }}
            // mt={{ base: '50px', md: '50px' }}
            // ml={{ base: '', md: '100px' }}
            display='flex'
            justifyContent={{ base: 'center', md: 'right' }}
            alignItems='center'
            // bg='blue'
          >
            <Image
              w='85%'
              // transform='rotateZ(15deg)'
              src={comm1big}
              borderRadius='45%'
            />
          </Box> */}
        </VStack>

        <VStack w='100%' gap='30px'>
          <Text
            w='100%'
            fontWeight='bold'
            fontSize={{ base: '20px', md: '30px' }}
            // textAlign={'end'}
          >
            Profile-Based Community
          </Text>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent='space-between'
            alignItems={'center'}
            w='100%'
          >
            <VStack w={{ base: '100%', md: '55%' }} gap='20px'>
              <Textbox img={pc1}>
                Develop skills with the best resources through interactive
                activities, games, and much more!
              </Textbox>
              <Textbox img={pc2}>
                Get access to industry experts and professionals who can provide
                valuable insights and guidance related to their interests and
                career goals.
              </Textbox>
              <Textbox img={pc3}>
                Engage in meaningful conversations, collaborate on projects, and
                participate in activities that align with your profile and
                interests.{' '}
              </Textbox>
              <Textbox img={pc4}>
                Be part of a positive learning environment that encourages
                exploration, innovation, and creativity.
              </Textbox>
            </VStack>
            <Center w='45%'>
              <Splide
                options={{
                  type: 'loop',
                  perPage: 3,
                  perMove: 1,
                  autoplay: true,
                  interval: 2000,
                  arrows: false,
                  pagination: false,
                  direction: 'ttb',
                  height: '300px',
                  // heightRatio: '1',
                }}
                hasTrack={false}
              >
                <SplideTrack>
                  {jobs.map(job => (
                    <SplideSlide>
                      <HStack
                        boxShadow='-1px 0px 5px #0000001a, 1px 0px 5px #0000001a'
                        borderRadius='0.25rem'
                        w={{ base: '250px', md: '300px' }}
                        h={{ base: '50px', md: '60px' }}
                        mx='10px'
                        my='0px'
                        p='10px'
                        display='flex'
                        justifyContent='center'
                        position='relative'
                        overflow='hidden'
                        bg='white'
                      >
                        {/* <Icon
                      position='absolute'
                      top='-60px'
                      left='-60px'
                      boxSize='150px'
                      color='#0000004b'
                      as={job.icon}
                    /> */}
                        <Text
                          // pl={{ base: '80px', md: '30px' }}
                          color='black'
                          fontSize={{ base: '20px', md: '30px' }}
                          // fontSize={splideFont}
                          fontWeight='bold'
                        >
                          {job.title}
                        </Text>
                      </HStack>
                    </SplideSlide>
                  ))}
                </SplideTrack>
              </Splide>
            </Center>
          </Stack>
        </VStack>

        <Stack mt='100px' direction={{ base: 'column', md: 'row' }} w='100%'>
          <VStack w='100%'>
            <Image
              boxSize={{ base: '250px', md: '200px', lg: '250px' }}
              src={prof2}
              color='#5387FF'
            ></Image>
            <Text>Weekly Profile based-games with Attractive Prizes</Text>
          </VStack>
          <VStack w='100%'>
            <Image
              boxSize={{ base: '250px', md: '200px', lg: '250px' }}
              src={prof1}
            ></Image>
            <Text>Meet the Industry Expert</Text>
          </VStack>
          <VStack w='100%'>
            <Image
              boxSize={{ base: '250px', md: '200px', lg: '250px' }}
              src={prof3}
            ></Image>
            <Text>Hands-on Workshops and Activities</Text>
          </VStack>
        </Stack>

        <Text
          mt='100px'
          w='100%'
          fontWeight='bold'
          fontSize={{ base: '20px', md: '35px' }}
          textAlign={'center'}
        >
          FAQ !
        </Text>
        <Accordion w={'100%'} defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton _hover={{}} borderRadius='10px' bg={'#5387FF'}>
                <Box w='100%' as='span' flex='1' textAlign='left'>
                  Do I need to install a separate app to join the community?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              No, We use Whatsapp and Discord as our mediums of communication.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _hover={{}} borderRadius='10px' bg='#5387FF'>
                <Box w='100%' as='span' flex='1' textAlign='left'>
                  Can I avail myself of all these features with a one-time
                  payment?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes, you get detailed psychometric analysis, a session, and
              2-month access to the Lucid community.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton _hover={{}} borderRadius='10px' bg='#5387FF'>
                <Box as='span' flex='1' textAlign='left'>
                  What if I don’t like the product?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              There is a complete refund policy after the duration of 2 months,
              check Refund Policy for more info.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>

      <Box
        w='80%'
        // bg='red'
        my='100px'
        gap='50px'
        display='flex'
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems='center'
        justifyContent='center'
        p='20px'
      >
        {pricing.map(p => (
          <VStack
            justifyContent='center'
            w={p.title.slice(0, 1) === 'M' ? '350px' : '300px'}
            h={p.title.slice(0, 1) === 'M' ? '550px' : '500px'}
            className='card'
          >
            <Box w={'100%'}>
              <VStack mb='20px' spacing='1px'>
                <Text
                  // h='35px'
                  color='#407BFF'
                  textAlign={'center'}
                  fontWeight='extrabold'
                  fontSize={{ base: '30px', md: '30px' }}
                  lineHeight={'100%'}
                >
                  {p.title}
                </Text>
                <Text
                  // h='35px'
                  color='#407BFF'
                  textAlign={'center'}
                  fontWeight='extrabold'
                  fontSize={{ base: '20px', md: '20px' }}
                  lineHeight={'100%'}
                  mb='20px'
                >
                  ({p.duration})
                </Text>
              </VStack>

              <Box
                w='100%'
                gap='5px'
                display='flex'
                justifyContent={'center'}
                alignItems='baseline'
              >
                <Text
                  h='30px'
                  color='#000000a4'
                  fontWeight='extrabold'
                  fontSize={{ base: '30px', md: '30px' }}
                >
                  ₹{p.price}
                </Text>
                <Text color='#858585'>{p.time}</Text>
              </Box>
            </Box>

            <Image boxSize={'200px'} src={p.img}></Image>
            <Button
              className='signup'
              bg='#407BFF'
              overflow='hidden'
              variant='unstyled'
              h='50px'
              color='white'
              border='1px solid #c5cbcc'
              fontWeight='bold'
              onClick={() => {
                if (localStorage.getItem('isLogin') === 'true') {
                  setProduct(p.product);
                  navigate('/checkout');
                } else {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0;
                  setShowLogin(true);
                }
              }}
              _hover={{ color: 'black' }}
              transitionDelay='0.3s'
            >
              ₹ {p.total}
            </Button>
          </VStack>
        ))}
      </Box>
    </Box>
  );
}
