import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  ChakraProvider,
  Button,
  Box,
  Text,
  keyframes,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Image,
  HStack,
  Center,
  SimpleGrid,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import './Landing.css';
import Top from '../Components/Top';
import { Body } from '../Components/Body';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import Socials from '../Components/Socials';
import caricature from '../assets/bgfinal.png';
import p17 from '../assets/1_6final.png';
import p812 from '../assets/7_12final.png';
import lp1 from '../assets/landingp1.gif';
import lp2 from '../assets/landingp2.gif';
import c11 from '../assets/comic2/c11.svg';
import c12 from '../assets/comic2/c12.svg';
import c13 from '../assets/comic2/c13.svg';
import c14 from '../assets/comic2/c14.svg';
import c21 from '../assets/comic2/c21.svg';
import c22 from '../assets/comic2/c22.svg';
import c23 from '../assets/comic2/c23.svg';
import c24 from '../assets/comic2/c24.svg';
import pill from '../assets/pill.jpg';
import Timeline from '../Components/Timeline';
import Playlist from './Playlist';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Community from './Community';
import Test from './Test';
import Services from './Services';
import Footer from '../Components/Footer';
import Login from './Login';
import { gsap } from 'gsap';
import { NavLink, useNavigate } from 'react-router-dom';

export default function Landing() {
  function handleClick() {
    var element = document.getElementById('timeline');
    var headerOffset = 96;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  const splideItems = [
    'Yourself',
    'Your Ideal Path',
    'The Perfect Community',
    // 'The Lucid Way',
  ];

  const splideFont = {
    base: '30px',
    sm: '40px',
    md: '45px',
    lg: '55px',
    xl: '70px',
  };

  const navigate = useNavigate()
  const [xpos, setXpos] = useState(0);
  const [ypos, setYpos] = useState(0);
  // function updateMouse(event) {
  //   setXpos(gsap.utils.mapRange(0, window.innerWidth, -20, 20, event.clientX));
  //   setYpos(gsap.utils.mapRange(0, window.innerHeight, -20, 20, event.clientY));
  // }
  // window.addEventListener('mousemove', updateMouse);

  // const xSet = gsap.quickTo('.pointer', 'x', { duration: 0.4, ease: 'power2' });
  // const ySet = gsap.quickTo('.pointer', 'y', { duration: 0.4, ease: 'power2' });

  // function movePointer() {
  //   xSet(xpos);
  //   ySet(ypos);
  // }
  // // gsap's RAF, falls back to set timeout
  // gsap.ticker.add(movePointer);

  const comic = [
    {
      title: 'The Boring Way',
      strip1: {
        img: c11,
        text: 'A linear system for students with different strengths, gifts, needs, and dreams.',
      },
      strip2: {
        img: c12,
        text: 'The boring routine: School, Extra classes, Tuition, Assignment and loads of tests.',
      },
      strip3: {
        img: c13,
        text: `Getting graded and compared based on a 'one size fits for all system which solely focuses on memorisation.`,
      },
      strip4: {
        img: c14,
        text: 'Confused about your future.',
      },
    },
    {
      title: 'The Lucid Way',
      strip1: {
        img: c21,
        text: 'A better understanding of your strengths, gifts, needs, and dreams.',
      },
      strip2: {
        img: c22,
        text: 'Learn your way with the help of a personalised learning framework specially tailored to your needs.',
      },
      strip3: {
        img: c23,
        text: 'Holistic development is fun and effortless with games, activities, and a personal mentor.',
      },
      strip4: {
        img: c24,
        text: 'Become the best at your strengths with the best resources and connect with peers and experts with similar interests.',
      },
    },
  ];

  const [isC1, setIsC1] = useState(true);

  const [isMd] = useMediaQuery('(min-width: 600px)');
  function moveBoring() {
    if (xpos === 0) {
      setXpos(50);
      setYpos(50);
      gsap.to('.boring-button', {
        duration: 0.1,
        x: isMd ? 300 : 0,
        y: isMd ? 0 : -100,
      });
    } else {
      gsap.to('.boring-button', { duration: 0.1, x: 0, y: 0 });
      setXpos(0);
      setYpos(0);
    }
  }

  function handleComicChange1() {
    if (!isC1) {
      setIsC1(true);
    }
    gsap.set('.comicbutt1', {
      background:
        '#5387FF',
    });
    gsap.set('.comicbutt2', { background: 'transparent' });
  }
  function handleComicChange2() {
    if (isC1) {
      setIsC1(false);
    }
    gsap.set('.comicbutt2', {
      background:
        '#5387FF',
    });
    gsap.set('.comicbutt1', { background: 'transparent' });
  }

  useEffect(() => {
    const changeTab = setInterval(() => {
      isC1 ? handleComicChange2() : handleComicChange1();
      setIsC1(isC1 ? false : true);
    }, 5000);

    return () => clearInterval(changeTab);
  }, [isC1]);

  const { showLogin, setShowLogin, isLogin, setIsLogin } =
    useContext(GlobalContext);

  const toast = useToast();

  return (
    <VStack w='100%' overflow='hidden' bg='transparent'>
      <VStack
        w={{ base: '100%', md: '80%' }}
        h={{ base: '80vh', md: '80vh', lg: '90vh' }}
        mt='50px'
        pt='5%'
        position='relative'
        // bg='red'
      >
        {/* <Box
          top='0%'
          right={{ base: '15%', md: '5%' }}
          display={{ base: 'none', sm: 'block' }}
          transform='skewY(10deg)'
          className='pointer'
          position='absolute'
          zIndex={0}
        >
          <Image
            boxSize={{ base: '100px', sm: '150px', md: '200px', lg: '300px' }}
            src={lp2}
          />
        </Box>

        <Box
          top={{ base: '0%', md: '55%' }}
          left='5%'
          display={{ base: 'none', sm: 'block' }}
          className='pointer'
          position='absolute'
          zIndex={0}
        >
          <Image
            boxSize={{ base: '100px', sm: '150px', md: '200px', lg: '300px' }}
            src={lp1}
          />
        </Box> */}

        {/* <Text
          // ml={{ base: '25px', md: '35px', lg: '50px' }}
          color='black'
          fontSize={{ base: '45px', md: '50px', lg: '70px' }}
          fontWeight='normal'
        >
          Find
        </Text> */}
        <Splide
          options={{
            type: 'loop',
            autoplay: true,
            interval: 2000,
            arrows: false,
            pagination: false,
            direction: 'ttb',
            height: '100px',
            // heightRatio: '0.1',
          }}
          hasTrack={false}
        >
          <SplideTrack>
            <SplideSlide>
              <Box w='100%'>
                <Text
                  // ml={{ base: '25px', md: '35px', lg: '50px' }}
                  color='black'
                  fontSize={{ base: '45px', md: '50px', lg: '70px' }}
                  fontWeight='bold'
                  textAlign={'center'}
                >
                  Find
                </Text>
              </Box>
            </SplideSlide>
            {/* <SplideSlide>
              <Box w='100%'>
                <Text
                  // ml={{ base: '25px', md: '35px', lg: '50px' }}
                  color='black'
                  fontSize={{ base: '45px', md: '50px', lg: '70px' }}
                  fontWeight='bold'
                  textAlign={'center'}
                >
                  Learn
                </Text>
              </Box>
            </SplideSlide> */}
          </SplideTrack>
        </Splide>
        <Splide
          options={{
            type: 'loop',
            autoplay: true,
            interval: 2000,
            arrows: false,
            pagination: false,
            direction: 'ttb',
            height: '100px',
            // heightRatio: '0.1',
          }}
          hasTrack={false}
        >
          <SplideTrack>
            {splideItems.map(item => (
              <SplideSlide>
                <Box w='100%'>
                  <Text
                    // ml={{ base: '25px', md: '35px', lg: '50px' }}
                    color='black'
                    fontSize={splideFont}
                    fontWeight='bold'
                    textAlign={'center'}
                  >
                    {item}
                  </Text>
                </Box>
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide>

        <Text
          pt='50px'
          zIndex={1}
          w={{ base: '90%', md: '60%' }}
          textAlign='center'
          fontSize={{ base: '15px', md: '20px' }}
        >
          At LucidDreams, we create personalized study frameworks designed to
          achieve holistic development. Unlock your full potential, embrace your
          uniqueness, and start your journey with us today
        </Text>

        <HStack pt='50px'>
          <Button
            w={{ base: '180px', md: '300px' }}
            h={{ base: '50px', md: '60px' }}
            bg='#5387FF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            fontSize={{ base: '0.8rem', md: '1.2rem' }}
            p='10px'
            _hover={{}}
            onClick={() => {
              navigate('/test');
              // localStorage.getItem('isLogin') === 'true'
              //   ? navigate('/test') 
              //   : setShowLogin(true);
            }}
          >
            Take the Lucid Profiling Test
          </Button>
          <Button
            w={{ base: '180px', md: '300px' }}
            h={{ base: '50px', md: '60px' }}
            // border='1px solid #109dab'
            bg='black'
            color='#5387FF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            fontSize={{ base: '0.8rem', md: '1.2rem' }}
            p='10px'
            _hover={{}}
            // animation={attractAnim}
            onClick={() => {
              // navigate('/services');
              localStorage.getItem('isLogin') === 'true'
                ? toast({
                    title: 'Success',
                    description: 'Callback has been booked.',
                    status: 'success',
                    duration: 10000,
                    isClosable: true,
                  })
                : setShowLogin(true);
            }}
            // isDisabled={watched ? false : true}
          >
            Book a Callback
          </Button>
        </HStack>
      </VStack>

      <VStack
        borderRadius={'20px'}
        bg=''
        // p='10px'
        justifyContent={'center'}
        minH='50vh'
        w='100%'
        overflow={'hidden'}
        spacing='0px'
      >
        <HStack w={{ base: '100%', md: '80%' }} bg='' spacing='0px'>
          <Button
            className='comicbutt1'
            variant='unstyled'
            w='100%'
            h='50px'
            bg='#5387FF'
            onClick={() => handleComicChange1()}
            borderTopRadius='20px'
            borderBottomRadius='0px'
            // fontFamily='BADABB'
            fontSize={{ base: '16px', md: '35px' }}
            // letterSpacing={'3px'}
            color='black'
            // textShadow={'-3px 3px white'}
          >
            {comic[0].title}
          </Button>
          <Button
            className='comicbutt2'
            variant='unstyled'
            w='100%'
            h='50px'
            bg=''
            onClick={() => handleComicChange2()}
            borderTopRadius='20px'
            borderBottomRadius='0px'
            // fontFamily='BADABB'
            fontSize={{ base: '16px', md: '35px' }}
            // letterSpacing={'3px'}
            color='black'
            // textShadow={'-3px 3px white'}
          >
            {comic[1].title}
          </Button>
        </HStack>

        <SimpleGrid
          borderBottomRadius='20px'
          bg='#5387FF'
          columns={{ base: 2, lg: 4 }}
          gap={'5px'}
          w={{ base: '100%', md: '80%' }}
          minH='100px'
          spacing='0'
        >
          <StoryBox
            desc={isC1 ? comic[0].strip1.text : comic[1].strip1.text}
            img={isC1 ? comic[0].strip1.img : comic[1].strip1.img}
          />
          <StoryBox
            desc={isC1 ? comic[0].strip2.text : comic[1].strip2.text}
            img={isC1 ? comic[0].strip2.img : comic[1].strip2.img}
          />
          <StoryBox
            desc={isC1 ? comic[0].strip3.text : comic[1].strip3.text}
            img={isC1 ? comic[0].strip3.img : comic[1].strip3.img}
          />
          <StoryBox
            desc={isC1 ? comic[0].strip4.text : comic[1].strip4.text}
            img={isC1 ? comic[0].strip4.img : comic[1].strip4.img}
          />
        </SimpleGrid>

        <Text
          py={{ base: '35px', md: '45px', lg: '70px' }}
          color='black'
          fontSize={{ base: '45px', md: '50px', lg: '70px' }}
          fontWeight='bold'
          textAlign={'center'}
        >
          What is it going to be ?
        </Text>

        <HStack
          spacing={{ base: '10px', md: '20px' }}
          pb={{ base: '35px', md: '45px', lg: '70px' }}
        >
          <Button
            w={{ base: '165px', md: '250px' }}
            h={{ base: '50px', md: '70px' }}
            bg='#5387FF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            p='10px'
            _hover={{}}
            fontSize={{ base: '20px', md: '20px' }}
            fontWeight={'bold'}
            onClick={handleClick}
          >
            Lucid Way
          </Button>
          <Button
            w={{ base: '165px', md: '250px' }}
            h={{ base: '50px', md: '70px' }}
            // border='1px solid #109dab'
            className='boring-button'
            bg='black'
            color='#5387FF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            p='10px'
            _hover={{}}
            fontSize={{ base: '20px', md: '20px' }}
            fontWeight={'bold'}
            onClick={moveBoring}
            onMouseOver={moveBoring}
          >
            Boring Way
          </Button>
        </HStack>

        {/* <HStack gap={'30px'}>
          <Image w='100px' src={pill} />
          <Image w='100px' src={pill} />
        </HStack> */}
      </VStack>

      <Body />

      <Timeline />
    </VStack>
  );
}

function StoryBox(props) {
  return (
    <VStack
      p='5px'
      w='100%'
      minH={{ base: '250px', md: '350px' }}
      borderRadius={'10px'}
      alignItems={'center'}
      justifyContent={'center'}
      // border='1px solid black'
    >
      <Image boxSize={{ base: '150px', md: '200px' }} src={props.img} />
      <Text w='70%' textAlign={'left'} color='black'>
        {props.desc}
      </Text>
    </VStack>
  );
}
