import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '../Context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  Divider,
  Link,
  Stack,
  VStack,
  HStack,
  Center,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  Heading,
  Flex,
  IconButton,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaWhatsapp, FaMailBulk, FaInstagram } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { InputNormal } from './Profile';

export default function Contact() {
  const toast = useToast();
  const { setShowLogin } = useContext(GlobalContext);
  return (
    <VStack py='2rem' spacing='2rem' w={'100%'} pt='4rem'>
      <Text fontSize='3rem' fontWeight={'bold'}>
        We would love to hear from you.
      </Text>
      <Stack
        spacing='1.5rem'
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
        w={'80%'}
      >
        <VStack spacing='1.5rem'>
          <Text
            pt='2rem'
            w={{ base: '95%', md: '30rem' }}
            textAlign='justify'
            fontSize={'1.2rem'}
            fontWeight={'semibold'}
          >
            Interested to know more about us ? Unsure if this is the right
            choice for you ? We have got yout covered. Contact us via whatsapp
            or email and one of our experts will get back to attend to all your
            queries. {`;)`}
          </Text>
          <HStack>
            <IconButton
              boxSize={'4rem'}
              fontSize='2rem'
              colorScheme='whatsapp'
              aria-label='whatsapp'
              icon={<FaWhatsapp />}
              onClick={() => {
                window.open('https://wa.me/9074359460');
              }}
            />
            <IconButton
              boxSize={'4rem'}
              fontSize='2rem'
              colorScheme='linkedin'
              aria-label='mail'
              icon={<GoMail />}
              onClick={() => {
                window.open('mailto:vivekmjn@gmail.com');
              }}
            />
            <IconButton
              boxSize={'4rem'}
              fontSize='2rem'
              bg='#e42970'
              color='white'
              aria-label='whatsapp'
              icon={<FaInstagram />}
              onClick={() => {
                window.open('https://wa.me/9074359460');
              }}
            />
          </HStack>
        </VStack>

        <VStack
          p='1.5rem'
          borderRadius={'15px'}
          boxShadow={
            '5px 5px 30px rgba(0, 0, 0, 0.102), -5px -5px 30px rgba(0, 0, 0, 0.102)'
          }
        >
          <SimpleGrid
            w={{ base: '95%', md: '30rem' }}
            spacing={'1rem'}
            columns={'1'}
          >
            <InputNormal>First Name</InputNormal>
            <InputNormal>Last Name</InputNormal>
            <InputNormal>Email</InputNormal>
            <InputNormal>Phone</InputNormal>
            <InputNormal>Query</InputNormal>
            <Button
              w={'100%'}
              h='50px'
              // border='1px solid #109dab'
              bg='black'
              color='#5387FF'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='15px'
              p='10px'
              _hover={{}}
              // animation={attractAnim}
              onClick={() => {
                // navigate('/services');
                localStorage.getItem('isLogin') === 'true'
                  ? toast({
                      title: 'Success',
                      description: 'Callback has been booked.',
                      status: 'success',
                      duration: 10000,
                      isClosable: true,
                    })
                  : setShowLogin(true);
              }}
              // isDisabled={watched ? false : true}
            >
              Book a Callback
            </Button>
          </SimpleGrid>
        </VStack>
      </Stack>
    </VStack>
  );
}
