import React, { useEffect, useState, useContext } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Divider,
  Link,
  VStack,
  HStack,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  keyframes,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AlertDescription,
  Tooltip,
  Center,
  UnorderedList,
  ListItem,
  ListIcon,
  List,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player/youtube';
import YouTube from 'react-youtube';
import { GlobalContext } from '../Context/GlobalContext';
import './Test.css';
import p1 from '../assets/step1p1.png';
import j1 from '../assets/j1.svg';
import j2 from '../assets/j2.svg';
import j4 from '../assets/j3.svg';
import j3 from '../assets/j4.svg';
import j5 from '../assets/j5.svg';
import sure from '../assets/sure.png';
import { FaEmpire } from 'react-icons/fa';
import { IoIosFlask } from 'react-icons/io';
import { GoTools } from 'react-icons/go';
import { GiTie } from 'react-icons/gi';
import { AiOutlineLineChart } from 'react-icons/ai';
import { FaPaintBrush } from 'react-icons/fa';
import { FcConferenceCall, FcFaq } from 'react-icons/fc';
import { NavLink, useNavigate } from 'react-router-dom';
import Top from '../Components/Top';
import { IoMdCheckmarkCircle } from 'react-icons/io';

export default function Test() {
  const events = [
    {
      title: 'Lab Luminary',
      description:
        'The student has high analytical and logical thinking skills and a strong aptitude for problem-solving and critical thinking. They also have a keen interest in exploring the underlying principles and mechanisms of the natural world and may be driven by a curiosity to understand complex systems and phenomena.',
      icon: IoIosFlask,
      color: '#138808',
      bg: '#3b6a4c',
      img: j1,
    },
    {
      title: 'Tech Titan',
      description:
        'The student has a high level of analytical and problem-solving abilities and a strong interest in understanding how things work. They naturally gravitate towards math and science and enjoy hands-on experimentation and exploration. They are detail-oriented and systematic in their approach to tasks and have the desire to create innovative solutions to complex problems.',
      icon: GoTools,
      color: '#0492c2',
      bg: '#0492c2',
      img: j2,
    },
    {
      title: 'Civic Sovereign',
      description:
        'The student has analytical solid and critical thinking skills, along with a desire to serve the public and positively impact society. They possess excellent communication, interpersonal, and organizational skills, a strong work ethic, and a commitment to ethical behavior. They are naturally inclined towards public policy, government administration, or public service leadership.',
      icon: GiTie,
      color: '#db0000',
      bg: '#c33514',
      img: j3,
    },
    {
      title: 'Money Maestro',
      description:
        'The student is strongly interested in numbers and statistics and naturally inclined toward math and data-driven decision-making. Detail-oriented and systematic in their approach to tasks, with a high level of precision and accuracy, and also possess excellent communication and presentation skills',
      icon: AiOutlineLineChart,
      color: 'black',
      bg: '#f5bb28',
      img: j4,
    },
    {
      title: 'Design Diva',
      description:
        'The student has high creativity, imagination, and aesthetic sensitivity. They are naturally inclined towards visual or performing arts, with a strong interest in exploring new and innovative ways of expressing themselves through art, possess a strong sense of individuality, excellent observational and interpretive skills, and a desire to communicate their ideas and emotions through their art.',
      icon: FaPaintBrush,
      color: '#460046',
      bg: '#8661c1',
      img: j5,
    },
  ];

  const navigate = useNavigate();
  const attract = keyframes`
  30% {
    transform: scale(1.05);
  }
  40%,
  60% {
    transform: rotate(-2deg) scale(1.05);
  }
  50% {
    transform: rotate(2deg) scale(1.05);
  }
  70% {
    transform: rotate(0deg) scale(1.05);
  }
  100% {
    transform: scale(1);
  }
  `;

  const attractAnim = `${attract} 2s ease infinite`;

  const [watched, setWatched] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    const changeTab = setInterval(() => {
      setTabIndex(tabIndex === 4 ? 0 : tabIndex + 1);
    }, 3000);

    return () => clearInterval(changeTab);
  }, [tabIndex]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { showLogin, setShowLogin, isLogin, setIsLogin } =
    useContext(GlobalContext);

  return (
    <Box
      w={{ base: '90%', md: '80%' }}
      minH='100vh'
      //   bg='aqua'
      color='black'
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap='30px'
      pb='50px'
      pt='50px'
    >
      {/* <Top /> */}

      <VStack
        direction={{ base: 'column', lg: 'row' }}
        spacing={'20px'}
        align='center'
        justifyContent='space-between'
        w='100%'
      >
        <Text fontWeight='extrabold' fontSize={{ base: '40px', md: '50px' }}>
          Step 1
        </Text>
        <Text
          fontWeight='extrabold'
          textAlign={'center'}
          fontSize={{ base: '40px', md: '50px' }}
        >
          Psychometric Profiling
        </Text>
        <Text
          w='70%'
          color='#808080'
          textAlign='center'
          fontWeight='normal'
          fontSize={{ base: '15px', md: '20px' }}
        >
          The Student Psychometric Test is a tool designed specifically for
          students to gain a deeper understanding of their personality and
          behavioral tendencies. Our team of experts has developed a
          classification system that categorizes students into five distinct
          profiles based on their responses to the psychometric test. This can
          help them make informed decisions about their academic and career
          paths and develop personal growth and success strategies.
        </Text>
        <Image boxSize='300px' src={p1} />
      </VStack>

      <Tabs
        index={tabIndex}
        w={{ base: '100%', lg: '100%' }}
        align='center'
        // variant='soft-rounded'

        onChange={index => setTabIndex(index)}
      >
        <TabList justifyContent='space-evenly' mb='10px'>
          {events.map(eve => (
            <Tab
              // w='100%'
              p='0'
              borderRadius='50%'
              // bg={eve.color}
              // color='black'
              color={eve.color}
              _selected={{
                // bg: 'transparent',
                bg: eve.bg,
                // color: eve.color,
                color: 'black',
                // border: '1px solid #A0AEC0',
                // borderBottomColor: '#ededed',
              }}
            >
              <Icon
                // bg={'black'}
                p='10px'
                boxSize={{ base: '50px', md: '65px' }}
                as={eve.icon}
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels borderTop=''>
          {events.map(eve => (
            <TabPanel p='0'>
              <Panel
                title={eve.title}
                color={eve.color}
                description={eve.description}
                img={eve.img}
                bg={eve.bg}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {/* <Alert w={{ base: '90vw', md: '80vw', lg: '60vw' }} status='info'>
        <AlertIcon />
        <AlertTitle>Watch this video to proceed !!</AlertTitle>
      </Alert>
      <Box
        w={{ base: '90vw', md: '80vw', lg: '60vw' }}
        h={{ base: '50vw', md: '45vw', lg: '35vw' }}
        bg='black'
      >
        <ReactPlayer
          width='100%'
          height='100%'
          url='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
          onEnded={() => {
            console.log('ended');
            setWatched(true);
          }}
        />
      </Box> */}

      <Stack
        w={{ base: '90vw', md: '80vw', lg: '60vw' }}
        mt='5rem'
        direction={{ base: 'column', md: 'row' }}
        justifyContent='center'
        gap='10px'
        // bg='red'
        // position='relative'
      >
        <VStack
          boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
          borderRadius='15px'
          p='1.5rem'
          w={{ base: '100%', md: '25rem' }}
          h='30rem'
          justifyContent={'space-between'}
        >
          <VStack spacing='2rem'>
            <Text
              textAlign={'center'}
              w='100%'
              fontSize='2rem'
              fontWeight='bold'
            >
              Lucid Profiling Test
              <Box w='100%' h={'1px'} bg={'rgba(0,0,0,0.3)'} />
            </Text>
            <List
              fontSize='1.1rem'
              // textAlign={'justify'}
              spacing='1rem'
              w='90%'
              stylePosition='outside'
            >
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='black' />
                  <Text lineHeight={'100%'}>Find your profile type</Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='black' />
                  <Text lineHeight={'100%'}>
                    Join your profile-based community
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='black' />
                  <Text lineHeight={'100%'}>
                    Unlock resources tailored for you
                  </Text>
                </HStack>
              </ListItem>
            </List>
          </VStack>
          <Button
            // w={{ base: '300px', md: '500px' }}
            h='50px'
            // border='1px solid #109dab'
            bg='black'
            color='#5387FF'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            p={{ base: '10px', md: '20px' }}
            _hover={{}}
            onClick={() => {
              navigate('/test/basictest');
              // if (localStorage.getItem('isLogin') === 'true') {
              //   navigate('/test/basictest');
              // } else {
              //   document.body.scrollTop = 0; // For Safari
              //   document.documentElement.scrollTop = 0;
              //   setShowLogin(true);
              // }
            }}
          >
            <Text
              fontSize={{ base: '15px', md: '20px' }}
              bg='linear-gradient(270deg, #00BDA6 46.5%, #5387FF 93.17%)'
              backgroundClip={'text'}
            >
              Take the FREE Test
            </Text>
          </Button>
        </VStack>
        <VStack
          boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
          // bg='linear-gradient(202.24deg, #792298 -22.58%, #BD5CDF 46.11%, #74278E 120.77%)'
          borderRadius='15px'
          p='1.5rem'
          w={{ base: '100%', md: '25rem' }}
          h='30rem'
          justifyContent={'space-between'}
        >
          <VStack spacing='2rem'>
            <Text
              bg='linear-gradient(270deg, #00BDA6 46.5%, #5387FF 93.17%)'
              backgroundClip={'text'}
              textAlign={'center'}
              w='100%'
              fontSize='2rem'
              fontWeight='bold'
            >
              Lucid Plus
              <Box w='100%' h={'1px'} bg={'rgba(0,0,0,0.3)'} />
            </Text>
            <List
              fontSize='1.1rem'
              // textAlign={'justify'}
              spacing='1rem'
              w='90%'
              stylePosition='outside'
            >
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='#00BDA6' />
                  <Text lineHeight={'100%'}>
                    Detailed psychometric Test with a 37-page detailed report
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='#00BDA6' />
                  <Text lineHeight={'100%'}>
                    One-on-one session with our expert counsellors
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='#00BDA6' />
                  <Text lineHeight={'100%'}>
                    Develop a unique learning framework.
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack alignItems={'top'}>
                  <ListIcon as={IoMdCheckmarkCircle} color='#00BDA6' />
                  <Text lineHeight={'100%'}>
                    Get a personal mentor and join the Lucid Plus Community
                  </Text>
                </HStack>
              </ListItem>
            </List>
          </VStack>
          <Button
            // w={{ base: '300px', md: '500px' }}
            h='50px'
            bg='linear-gradient(270deg, #00BDA6 46.5%, #5387FF 93.17%)'
            display='flex'
            justifyContent='center'
            alignItems='center'
            borderRadius='15px'
            fontSize={{ base: '15px', md: '20px' }}
            p={{ base: '10px', md: '20px' }}
            _hover={{}}
            animation={attractAnim}
            onClick={() => {
              navigate('/services');
            }}
            // isDisabled={watched ? false : true}
          >
            Explore Lucid Plus
          </Button>
        </VStack>
      </Stack>
    </Box>
  );
}

function Panel(props) {
  return (
    <Stack
      // w='90%'
      // p='20px'
      borderRadius='50px'
      textAlign='start'
      minH='400px'
      bg={props.bg}
      boxShadow={`0 0 20px 1px ${props.bg}`}
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'center', md: 'flex-start' }}
      spacing={{ base: '0', md: '60px' }}
      p='1rem'
    >
      <VStack p='20px' pt={{ base: '0', md: '100px' }} align='start'>
        <Text
          fontWeight='bold'
          color={'black'}
          fontSize={{ base: '20px', md: '35px' }}
        >
          {props.title}
        </Text>
        <Text
          color={'black'}
          textAlign='justify'
          w={{ base: '100%', md: '100%' }}
          fontWeight='normal'
          fontSize={{ base: '15px', md: '20px' }}
        >
          {props.description}
        </Text>
      </VStack>
      <Image
        pt={{ base: '0', md: '40px' }}
        src={props.img}
        w={{ base: '200px', md: '300px' }}
      />
    </Stack>
  );
}

export function Textbox({ img, children }) {
  return (
    <HStack
      w='100%'
      minH='30px'
      borderRadius={'10px'}
      p='5px'
      boxShadow='0 1px 5px rgba(0, 0, 0, 0.15)'
      _hover={{ boxShadow: '0 5px 5px rgba(0, 0, 0, 0.10)' }}
    >
      <Box minW='60px' h='60px'>
        {/* <Icon as={FcFaq} boxSize='60px' /> */}
        <Image src={img} boxSize='60px' />
      </Box>
      <HStack>
        {/* <Text fontWeight={'bold'}>Title here</Text> */}
        <Box bg='black' borderRadius='50%' boxSize='5px'></Box>
        <Text fontWeight='semibold' fontSize={{ base: '15px', md: '17px' }}>
          {children}
        </Text>
      </HStack>
    </HStack>
  );
}
