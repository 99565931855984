import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  HStack,
  VStack,
  StackDivider,
  Icon,
  useColorModeValue,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { IoLogoBitcoin } from 'react-icons/io';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';
import { FaHandPointRight } from 'react-icons/fa';
import { CheckIcon } from '@chakra-ui/icons';
import Top from '../Components/Top';
import aboutus from '../assets/aboutus.svg';

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function Aboutus() {
  return (
    <Container minH='100vh' maxW={'6xl'} py={12}>
      {/* <Top /> */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
          >
            Our Story
          </Text>
          <Heading>
            We are not just another company that provides career counselling!
          </Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            We are students, teachers, doctors, lawyers, designers – you name
            it. Every day we strive to provide services that we are proud of for
            the students that we believe in. We believe everyone has what it
            takes to be anyone they want to be but some of us just need a little
            push, a little support to tip them over the edge and that is what we
            at LucidDreams provide
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Feature
              icon={
                <Icon as={FaHandPointRight} color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Psychometric Profiling'}
            />
            <Feature
              icon={
                <Icon as={FaHandPointRight} color={'green.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Map Your Ideal Future'}
            />
            <Feature
              icon={
                <Icon as={FaHandPointRight} color={'purple.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Join the Lucid Clan'}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={aboutus}
            
          />
        </Flex>
      </SimpleGrid>
      {/* <Companyjist /> */}
      <Companygoals />
      {/* <Companycurrent /> */}
    </Container>
  );
}

function Companyjist() {
  const Feature = ({ title, text, icon }) => {
    return (
      <Stack>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={'gray.100'}
          mb={1}
        >
          {icon}
        </Flex>
        <Text fontWeight={600}>{title}</Text>
        <Text color={'gray.600'}>{text}</Text>
      </Stack>
    );
  };
  return (
    <Box mt='50px' p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={FcAssistant} w={10} h={10} />}
          title={'Lifetime Support'}
          text={
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          }
        />
        <Feature
          icon={<Icon as={FcDonate} w={10} h={10} />}
          title={'Unlimited Donations'}
          text={
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          }
        />
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'Instant Delivery'}
          text={
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore...'
          }
        />
      </SimpleGrid>
    </Box>
  );
}

function Companygoals() {
  const features = [
    `Get to know yourself - Discover your personality and profile to find out what is stopping you from getting what you want.`,
    'Meet with experts - Talk to professionals to eliminate your weaknesses and enhance your strengths.',
    'Join the community - Get mentorship and be part of the community. Its a whole big world out there.',
    'And finally, take control of your dreams.',
    'Personalized learning',
    'Empowering students',
    'Accessible to all',
    'At a reasonable price',
  ];

  return (
    <Box mt='50px' p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={'3xl'}>What we Strive to Achieve...</Heading>
        <Text color={'gray.600'} fontSize={'xl'}>
          We are the platform where you can find the people that are doing what
          you want to do.
        </Text>
      </Stack>

      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map(feature => (
            <HStack align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600}>{feature}</Text>
                {/* <Text color={'gray.600'}>{feature.text}</Text> */}
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

function StatsCard({ title, stat }) {
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
    >
      <StatLabel fontWeight={'medium'} isTruncated>
        {title}
      </StatLabel>
      <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
        {stat}
      </StatNumber>
    </Stat>
  );
}

function Companycurrent() {
  return (
    <Box maxW='7xl' mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}
      >
        What is our company doing?
      </chakra.h1>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard title={'We serve'} stat={'50,000 people'} />
        <StatsCard title={'In'} stat={'30 different countries'} />
        <StatsCard title={'Who speak'} stat={'100 different languages'} />
      </SimpleGrid>
    </Box>
  );
}
