import React, { useContext } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  keyframes,
  HStack,
  useDisclosure,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import logo from '../assets/logo4.png';
import { useState } from 'react';
import { AiOutlineLogin, AiOutlineLogout } from 'react-icons/ai';
import { HamburgerIcon } from '@chakra-ui/icons';
import AOS from 'aos';
import axios from 'axios';
import 'aos/dist/aos.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { BiUser } from 'react-icons/bi';
import { gsap } from 'gsap';

export default function Top() {
  const fadeInTop = keyframes`
    from { margin-top: -10px; opacity: 0 }
    to { margin-top: 20px; }
  `;
  const fadeInTopAnim = `${fadeInTop} 1s linear`;

  const navItems = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Test',
      link: '/test',
    },
    {
      name: 'Counseling',
      link: '/services',
    },
    {
      name: 'Community',
      link: '/community',
    },
    {
      name: 'Accessories',
      link: '/accessories',
    },
    // {
    //   name: 'Resources',
    //   link: '/aboutus',
    // },
    // {
    //   name: 'Profile',
    //   link: '/profile',
    // },
  ];

  const navItems2 = [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'Test',
      link: '/test',
    },
    {
      name: 'Counseling',
      link: '/services',
    },
    {
      name: 'Community',
      link: '/community',
    },
    {
      name: 'Accessories',
      link: '/accessories',
    },
    {
      name: 'About Us',
      link: '/aboutus',
    },
    {
      name: 'Blog',
      link: '/blog',
    },
    {
      name: 'Contact Us',
      link: '/contactus',
    },
  ];

  const {
    showLogin,
    setShowLogin,
    email,
    setEmail,
    phone,
    setPhone,
    name,
    setName,
    username,
    setUsername,
    profile,
    setProfile,
    isLogin,
    setIsLogin,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLogout = async () => {
    console.log('logout');
    try {
      const response = await axios.post(
        `https://luciddreams.in/api/auth/logout`
        // `https://luciddreams-site.onrender.com/api/auth/logout`
        // `http://localhost:5000/api/auth/logout`
      );
      if (response.status == 200) {
        console.log(response.data);
        localStorage.setItem('isLogin', 'false');
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      // toast({
      //   title: 'Error',
      //   description: error.response.data.message,
      //   status: 'error',
      //   duration: 10000,
      //   isClosable: true,
      // });
    }
    setName('');
    setEmail('');
    setPhone('');
    setUsername('');
    setProfile('');
  };

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 25 ||
      document.documentElement.scrollTop > 25
    ) {
      gsap.to('.topbar', {
        duration: 0.5,
        background:
          '#5387FF',
        ease: 'out',
      });
    } else {
      gsap.to('.topbar', {
        duration: 0.5,
        background: 'transparent',
        ease: 'in',
      });
    }
  }

  return (
    <Box
      className='topbar'
      // pt={{ base: '10px', md: '15px', lg: '30px' }}
      py='10px'
      // bg='linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'
      w={{ base: '100%', md: '80%' }}
      borderRadius={{base: '0px', sm: '10px', md: '20px'}}
      // bg='#c5cbcc'
      // bg='red'
      display='flex'
      justifyContent='space-between'
      alignItems='bottom'
      animation={fadeInTopAnim}
      position='sticky'
      top={{ base: '0px', md: '40px' }}
      zIndex={5}
    >
      <HStack
        as={NavLink}
        to={'/'}
        px='10px'
        w={{ base: '150px', md: '170px', lg: '200px', xl: '220px' }}
      >
        <Image src={logo} />
      </HStack>

      <HStack
        mx='10px'
        minW='150px'
        display='flex'
        justifyContent={{ base: 'end', lg: 'center' }}
        zIndex={10}
      >
        <HStack display={{ base: 'none', lg: 'flex' }} spacing='20px'>
          {navItems.map(item => (
            <Link
              as={NavLink}
              to={item.link}
              color='blackAlpha.800'
              _hover={{ color: 'black' }}
            >
              <Text fontWeight={'bold'}>{item.name}</Text>
            </Link>
          ))}
          <Menu>
            <MenuButton
              _hover={{ color: 'black' }}
              color='blackAlpha.800'
              bg=''
            >
              <Text fontWeight={'bold'}>Resources</Text>
            </MenuButton>
            <MenuList>
              <MenuItem as={NavLink} to={'/aboutus'}>
                About Us
              </MenuItem>
              <MenuItem as={NavLink} to={'/blog'}>
                Blog
              </MenuItem>
              <MenuItem as={NavLink} to={'/contactus'}>
                Contact Us
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        <HStack spacing='10px'>
          <HStack
            display={
              localStorage.getItem('isLogin') === 'true' ? 'none' : 'flex'
            }
          >
            <Button
              // w={{ base: '300px', md: '500px' }}
              // h='50px'
              bg=''
              // color='#5653FF'
              border='1px solid black'
              justifyContent='center'
              alignItems='center'
              borderRadius='15px'
              p='10px'
              _hover={{}}
              display={
                localStorage.getItem('isLogin') === 'true' ? 'none' : 'flex'
              }
              onClick={() => {
                setShowLogin(true);
              }}
            >
              Login
            </Button>
            <Button
              bg='black'
              border='1px solid black'
              color='#5387FF'
              justifyContent='center'
              alignItems='center'
              borderRadius='15px'
              p='10px'
              _hover={{}}
              // display={name === '' ? 'flex' : 'none'}
              display={
                localStorage.getItem('isLogin') === 'true' ? 'none' : 'flex'
              }
              onClick={() => {
                setShowLogin(true);
              }}
            >
              {localStorage.getItem('isLogin') === 'true'
                ? 'Logout'
                : 'Sign Up'}
            </Button>
          </HStack>
          <Menu>
            <MenuButton
              display={
                localStorage.getItem('isLogin') === 'true' ? 'flex' : 'none'
              }
              _hover={{ color: 'black' }}
              color='blackAlpha.800'
            >
              <HStack spacing='0'>
                <Icon as={BiUser} fontSize={'30px'} />
                {/* <Text pt='5px' fontSize={'20px'}>
                  {name.split(' ').shift()}
                </Text> */}
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem as={NavLink} to={'/profile'}>
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Menu>
          <IconButton
            variant='unstyled'
            color='black'
            bg='white'
            size={'lg'}
            icon={
              isOpen ? (
                <HamburgerIcon
                  boxSize={8}
                  transform={'rotate(-90deg)'}
                  transitionDuration={'0.8s'}
                />
              ) : (
                <HamburgerIcon boxSize={8} transitionDuration={'0.8s'} />
              )
            }
            aria-label={'Open Menu'}
            display={{ lg: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
        </HStack>
      </HStack>

      <Box
        fontWeight={700}
        fontSize='16px'
        zIndex={5}
        // bg='red'
        // height={'calc(100vh - 6rem)'}
        display={{ lg: 'none' }}
        // py={4}
        pr={5}
        position={'absolute'}
        // top={'4rem'}
        width={'100%'}
        top={isOpen ? '4rem' : '-500px'}
        transitionDuration={'0.8s'}
        onClick={onClose}
      >
        <Flex justifyContent={'end'} alignItems={'center'}>
          <VStack
            borderRadius='10px'
            bg='black'
            p='20px'
            alignItems={'end'}
            as={'nav'}
            spacing={4}
          >
            {navItems2.map(item => (
              <Link
                as={NavLink}
                to={item.link}
                color='whiteAlpha.800'
                _hover={{ color: 'white' }}
              >
                <Text>{item.name}</Text>
              </Link>
            ))}
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
}
