import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  Divider,
  Link,
  Stack,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  Heading,
  Flex,
  IconButton,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

export default function Terms() {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <VStack w={{ base: '90%', md: '80%' }}>
      <Heading size='2xl' py='5rem'>
        Terms and Conditions
      </Heading>
      <UnorderedList>
        <ListItem>
          VISITORS TO THIS WEB SITE ARE BOUND BY THE FOLLOWING TERMS AND
          CONDITIONS ("TERMS") SO PLEASE READ THESE CAREFULLY BEFORE CONTINUING
          TO USE THIS SITE. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, PLEASE
          DO NOT USE THIS SITE.
        </ListItem>
        <ListItem>
          Although LucidDreams tries to ensure that all information and
          recommendations, whether in relation to the products, services,
          offerings or otherwise (including, but not limited to, directories,
          guides, news articles, opinions, reviews, text, photographs, images,
          illustrations, profiles, audio clips, video clips, trademarks, service
          marks and the like, hereinafter referred to as "Information") provided
          as part of this website is correct at the time of inclusion on the web
          site, LucidDreams does not guarantee the accuracy of the Information.
          LucidDreams makes no representations or warranties as to the
          completeness or accuracy of Information.
        </ListItem>
        <ListItem>
          LucidDreams makes no commitment to update or correct any Information
          that appears on the Internet or on this web site.
        </ListItem>
        <ListItem>
          Information is supplied upon the condition that the persons receiving
          the same will make their own determination as to its suitability for
          their purposes prior to use or in connection with the making of any
          decision. Any use of this website or the Information is at your own
          risk. Neither LucidDreams nor their Directors, officers, employees or
          agents shall be liable for any loss, damage or expense arising out of
          any access to, use of, or reliance upon, this website or the
          Information, or any website linked to this website.
        </ListItem>
        <ListItem>
          Nothing contained herein is to be construed as a recommendation to use
          any product, process, equipment or formulation, in conflict with any
          patent, or otherwise and LucidDreams makes no representation or
          warranty, express or implied that, the use thereof will not infringe
          any patent, or otherwise.
        </ListItem>
        <ListItem>
          All information displayed, transmitted or carried on this website is
          protected by copyright and other intellectual property laws. By
          accessing this website, a user agrees not to modify, publish,
          transmit, transfer, sell, reproduce, create derivative work from,
          distribute, re-post, perform, display or in any way commercially
          exploit any of the Information. You agree to abide by all copyright
          notices and restrictions attached to any content accessed through the
          LucidDreams Web Site, not to alter the content in any way.
        </ListItem>
        <ListItem>
          THIS WEBSITE IS PROVIDED TO YOU ON AN "AS IS" AND "WHERE-IS" BASIS,
          WITHOUT ANY WARRANTY. LUCIDDREAMS, FOR ITSELF AND ANY THIRD PARTY
          PROVIDING MATERIALS, SERVICES, OR CONTENT TO THIS WEBSITE, MAKES NO
          REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS, IMPLIED, STATUTORY OR
          OTHERWISE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT OF THIRD PARTY RIGHTS, WITH RESPECT TO THE WEBSITE,
          THE INFORMATION OR ANY PRODUCTS OR SERVICES TO WHICH THE INFORMATION
          REFERS. LUCIDDREAMS WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR
          ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, DIRECT,
          INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING FROM
          OR CONNECTED WITH THE SITE, INCLUDING BUT NOT LIMITED TO, YOUR USE OF
          THIS SITE OR YOUR INABILITY TO USE THE SITE, EVEN IF LUCIDDREAMS HAS
          PREVIOUSLY BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </ListItem>
        <ListItem>
          Lucid controls and operates this web site from India and makes no
          representation that the materials are appropriate or will be available
          for use in other locations. If you use this web site from outside the
          India, you are entirely responsible for compliance with all applicable
          local laws.
        </ListItem>
        <ListItem>
          In your use of the web site, you may enter into correspondence with,
          purchase goods and/or services from, or participate in promotions of
          advertisers or members or sponsors of the web site. Unless otherwise
          stated, any such correspondence, advertisement, purchase or promotion,
          including the delivery of and the payment for goods and/or services,
          and any other term, condition, warranty or representation associated
          with such correspondence, purchase or promotion, is solely between you
          and the applicable third party. You agree that LucidDreams has no
          liability, obligation or responsibility for any such correspondence,
          purchase or promotion between you and any such third party.
        </ListItem>
        <ListItem>
          This website may be linked to other websites that are not under the
          control of or maintained by LucidDreams. Such links do not indicate
          any responsibility or endorsement on our part for the external website
          concerned, its contents or the links displayed on it. These links are
          provided only as a convenience, in order to help you find relevant
          websites, services and/or products that may be of interest to you,
          quickly and easily. It is your responsibility to decide whether any
          services and/or products available through any of these websites are
          suitable for your purposes. LucidDreams is not responsible for the
          owners or operators of these websites or for any goods or services
          they supply or for the content of their websites and does not give or
          enter into any conditions, warranties or other terms or
          representations in relation to any of these or accept any liability in
          relation to any of these (including any liability arising out of any
          claim that the content of any external web site to which this web site
          includes a link infringes the intellectual property rights of any
          third party).
        </ListItem>
        <ListItem>
          These terms and conditions are governed by and to be interpreted in
          accordance with laws of India, without regard to the choice or
          conflicts of law provisions of any jurisdiction. You agree, in the
          event of any dispute arising in relation to these terms and conditions
          or any dispute arising in relation to the web site whether in contract
          or tort or otherwise, to submit to the jurisdiction of the courts
          located at Kozhikode, India for the resolution of all such disputes.
        </ListItem>
      </UnorderedList>
    </VStack>
  );
}
