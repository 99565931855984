import { React, useState, useRef, useEffect, useContext } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  Divider,
  Link,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Center,
  Icon,
  IconButton,
  FormControl,
  FormLabel,
  Heading,
  Menu,
  MenuButton,
  Input,
  InputRightElement,
  InputGroup,
  MenuList,
  MenuItem,
  useToast,
  keyframes,
  Checkbox,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import bg from '../assets/52.png';
import logo from '../assets/logo4.png';
import { InputNormal, InputPassword } from './Profile';
import { CloseIcon } from '@chakra-ui/icons';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function Login() {
  const [newacc, setNewacc] = useState(false);
  const [useEmail, setUseEmail] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isTerms, setIsTerms] = useState(false);

  const {
    showLogin,
    setShowLogin,
    email,
    setEmail,
    phone,
    setPhone,
    name,
    setName,
    username,
    setUsername,
    password,
    setPassword,
    profile,
    setProfile,
    isLogin,
    setIsLogin,
  } = useContext(GlobalContext);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowLogin(true);
  //   }, 2000);
  // }, []);

  const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;
  const fadeInAnim = `${fadeIn} 0.5s ease-in-out`;

  const toast = useToast();
  const handleAuth = async () => {
    if (newacc) {
      handleRegister();
    } else {
      handleLogin();
    }
    // console.log(data);
  };

  const handleRegister = async () => {
    setLoading(true);
    var data = {
      name: name,
      phone: phone,
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(
        `https://luciddreams.in/api/auth/register`,
        // `https://luciddreams-site.onrender.com/api/auth${url}`,
        // `http://localhost:5000/api/auth${url}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status == 200) {
        setLoading(false);
        // console.log(response.data);
        setShowLogin(false);
        handleLogin();
      } else {
        setLoading(false);
        toast({
          title: 'Error',
          description: response.data.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    var data = {
      password: password,
    };
    useEmail ? (data.email = email) : (data.phone = phone);
    try {
      const response = await axios.post(
        `https://luciddreams.in/api/auth/login`,
        // `https://luciddreams-site.onrender.com/api/auth${url}`,
        // `http://localhost:5000/api/auth${url}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status == 200) {
        setLoading(false);
        // console.log(response.data);
        setShowLogin(false);
        setIsLogin(true);
        localStorage.setItem('isLogin', 'true');
        setName(response.data.name);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setUsername(
          response.data.username != undefined ? response.data.username : ''
        );
        setProfile(
          response.data.profile != undefined ? response.data.profile : ''
        );
        toast({
          title: 'Welcome',
          description: `Hi ${response.data.name}, Welcom to Lucid.`,
          status: 'success',
          duration: 10000,
          isClosable: true,
        });
      } else {
        setLoading(false);
        toast({
          title: 'Error',
          description: response.data.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack
      position='absolute'
      top='-10'
      backdropFilter='blur(10px)'
      bg='blackAlpha.600'
      zIndex={10}
      pt='10%'
      w='100%'
      h='100%'
      display={showLogin ? 'flex' : 'none'}
      animation={fadeInAnim}
    >
      <VStack
        // position='sticky'
        // top={{ base: '50px', md: '100px' }}
        mt={{ base: '100px', md: '0px' }}
        borderRadius='10px'
        py='20px'
        w={{ base: '95%', sm: '300px', md: '400px' }}
        boxShadow='5px 5px 30px rgba(0, 0, 0, 0.102), -5px -5px 30px rgba(0, 0, 0, 0.102)'
        bg='#ededed'
        // bg='red'
        px='30px'
        spacing='0px'
      >
        <HStack justifyContent='space-between' mb='50px' w='100%'>
          <IconButton
            onClick={() => {
              setShowLogin(false);
              setLoading(false);
            }}
            _hover={{}}
            icon={<CloseIcon />}
          />
          <HStack>
            <Text fontWeight='semibold'>
              {newacc ? 'Already a member?' : 'New Member?'}
            </Text>
            <Button
              borderRadius='5px'
              bg='#109dab'
              _hover={{ bg: 'blue.300' }}
              onClick={() => {
                newacc ? setNewacc(false) : setNewacc(true);
              }}
            >
              {newacc ? 'Sign In' : 'Sign Up'}
            </Button>
          </HStack>
        </HStack>
        <VStack w='100%' align='start' spacing='0' fontWeight='bold'>
          <Text fontSize='30px'>Welcome To Lucid</Text>
          <Text fontSize='20px' color='blackAlpha.500'>
            {newacc ? 'Register your Account' : 'Sign In to your Account'}
          </Text>
          {newacc && (
            <VStack pt='50px' pb='20px' w='100%'>
              <InputNormal val={name} setVar={setName} placeholder='Name'>
                Name
              </InputNormal>
              <InputNormal val={email} setVar={setEmail} placeholder='Email'>
                Email
              </InputNormal>
              <InputNormal val={phone} setVar={setPhone} placeholder='Phone'>
                Phone No
              </InputNormal>
              <InputPassword val={password} setVar={setPassword}>
                Password
              </InputPassword>
              <Checkbox
                onChange={() => {
                  isTerms ? setIsTerms(false) : setIsTerms(true);
                }}
                fontWeight={'normal'}
                colorScheme='messenger'
              >
                By proceeding, you agree to the{' '}
                <Link
                  color='#0645AD'
                  isExternal
                  as={NavLink}
                  to={'/termsandconditions'}
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  color='#0645AD'
                  isExternal
                  as={NavLink}
                  to={'/privacypolicy'}
                >
                  Privacy Policy
                </Link>
              </Checkbox>
            </VStack>
          )}
          {!newacc && (
            <VStack pt='50px' pb='20px' w='100%'>
              <InputNormal
                val={useEmail ? email : phone}
                setVar={useEmail ? setEmail : setPhone}
                placeholder={useEmail ? 'Email' : 'Phone Number'}
              >
                {useEmail ? 'Email' : 'Phone Number'}
              </InputNormal>
              <InputPassword val={password} setVar={setPassword}>
                Password
              </InputPassword>
              <Text
                pt='10px'
                onClick={() => {
                  setUseEmail(useEmail ? false : true);
                  setEmail('');
                  setPhone('');
                }}
                color='#109dab'
                cursor={'pointer'}
                w='100%'
                textAlign={'left'}
              >
                {useEmail ? 'Use Phone Number' : 'Use Email'}
              </Text>
            </VStack>
          )}
        </VStack>

        <Button
          w='100%'
          borderRadius='5px'
          bg='#109dab'
          _hover={{ bg: 'blue.300' }}
          onClick={handleAuth}
          isLoading={loading}
        >
          {newacc ? 'Register' : 'Log In'}
        </Button>
        {/* <Button
          w='100%'
          variant='solid'
          border='2px solid black'
          leftIcon={<FcGoogle />}
        >
          <Center>
            <Text>{newacc ? 'Sign Up' : 'Sign In'} with Google</Text>
          </Center>
        </Button> */}
      </VStack>
    </VStack>
  );
}
