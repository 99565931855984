import React, { useEffect, useState, useContext } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  Divider,
  Link,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  IconButton,
  FormControl,
  FormLabel,
  Heading,
  Menu,
  MenuButton,
  Input,
  InputRightElement,
  InputGroup,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import ill3 from '../assets/ill3.jpg';
import avatar from '../assets/avatar1.png';
import { GlobalContext } from '../Context/GlobalContext';
import { FaCamera, FaPen } from 'react-icons/fa';
import { ChevronDownIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import Top from '../Components/Top';
import j1 from '../assets/j1.svg';
import j2 from '../assets/j2.svg';
import j4 from '../assets/j3.svg';
import j3 from '../assets/j4.svg';
import j5 from '../assets/j5.svg';

export default function Profile() {
  const {
    showLogin,
    setShowLogin,
    email,
    setEmail,
    phone,
    setPhone,
    name,
    setName,
    username,
    setUsername,
    password,
    setPassword,
    profile,
    setProfile,
  } = useContext(GlobalContext);

  const user = {
    Name: name,
    Username: username,
    Email: email,
    Address: '',
    Phone: phone,
  };

  const [edit, setEdit] = useState(false);

  function sendReq() {
    if (edit == true) {
      console.log('Sending Req');
    }
  }

  const [pImg, setPImg] = useState(ill3);
  useEffect(() => {
    if (profile === 'Lab Luminary') setPImg(j1);
    else if (profile === 'Tech Titan') setPImg(j2);
    else if (profile === 'Civil Sovereign') setPImg(j3);
    else if (profile === 'Money Maestro') setPImg(j4);
    else if (profile === 'Design Diva') setPImg(j5);
    else setPImg(ill3);
  }, [profile]);

  return (
    <VStack w='100vw' py={'50px'} minH='100vh' overflowX='hidden'>
      {/* <Top /> */}
      <Text
        w={{ base: '80%', md: '75%' }}
        color='black'
        textAlign={{ base: 'center', md: 'start' }}
        fontSize={{ base: '40px', md: '50px' }}
        fontWeight='normal'
      >
        Profile Settings
      </Text>
      <Box
        w='100vw'
        display='flex'
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent='center'
        alignItems={{ base: 'center', lg: 'start' }}
        pt={{ base: '30px', md: '30px' }}
        gap='20px'
      >
        <Box w='200px' position='relative'>
          <Image
            src={pImg}
            boxSize='200px'
            border='1px solid #4dce9e'
            borderRadius='50%'
          />
          {/* <Button
            position='absolute'
            _hover={{ color: '#ededed' }}
            bottom='70px'
            right='10px'
            bg='#4dce9e'
            boxSize='40px'
            borderRadius='20px'
            fontSize='20px'
          >
            <Icon as={FaCamera} />
            <Input
              type='file'
              accept='image/*'
              height='100%'
              width='100%'
              position='absolute'
              top='0'
              left='0'
              opacity='0'
              borderRadius='0.25rem'
            />
          </Button> */}
          <Button
            // rightIcon={<FaPen />}
            variant='unstyled'
            bg='black'
            color='white'
            w='100%'
            mt='20px'
            borderRadius='5px'
            _hover={{ boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)' }}
            // onClick={() => {
            //   edit ? setEdit(false) : setEdit(true);
            //   sendReq();
            // }}
          >
            {/* {edit ? 'Update Profile' : 'Edit Profile'} */}
            {profile === '' ? 'Your Profile' : profile}
          </Button>
        </Box>
        <VStack
          w={{ base: '80%', lg: '60%' }}
          // bg='aqua'
          mb='50px'
          py='20px'
          px={{ base: '0', md: '50px' }}
          borderRadius='10px'
          // h='1000px'
        >
          {Object.entries(user).map(([key, val]) => (
            <Box w='100%'>
              {!edit && <DisplayDetails title={key}>{val}</DisplayDetails>}
              {edit && <InputNormal placeholder={val}>{key}</InputNormal>}
            </Box>
            // <DisplayDetails title={key}>{val}</DisplayDetails>
            // <InputNormal placeholder={val}>{key}</InputNormal>
          ))}
        </VStack>
      </Box>
    </VStack>
  );
}

const DisplayDetails = ({ title, children }) => {
  return (
    <VStack align='start' w='100%'>
      <Text fontWeight='bold'>{title}</Text>
      <Text
        w='100%'
        borderBottom='1px solid #0000001f'
        p='5px'
        borderRadius='5px'
      >
        {children}
      </Text>
    </VStack>
  );
};

export const InputNormal = ({ placeholder, val, setVar, children }) => {
  return (
    <Box w='100%'>
      <FormControl isRequired={true} id='Name'>
        <FormLabel fontWeight='bold'>{children}</FormLabel>
        <Input
          value={val}
          placeholder={placeholder}
          type='text'
          border='0'
          borderRadius='0'
          borderBottom='1px solid #0000001f'
          onChange={event => {
            setVar(event.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};

export const InputPassword = ({ val, setVar, children }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl w='100%' id='password' isRequired>
      <FormLabel>{children}</FormLabel>
      <InputGroup>
        <Input
          border='0'
          value={val}
          borderRadius='0'
          borderBottom='1px solid #0000001f'
          placeholder={children}
          type={showPassword ? 'text' : 'password'}
          onChange={event => {
            setVar(event.target.value);
          }}
        />
        <InputRightElement h={'full'}>
          <Button
            variant={'ghost'}
            onClick={() => setShowPassword(showPassword => !showPassword)}
          >
            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};
