import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  keyframes,
  Stack,
  Icon,
  HStack,
} from '@chakra-ui/react';
import bg from '../assets/bg.svg';
import signBoard from '../assets/signboard.png';
import signmantext from '../assets/signmantext.png';
import signman2 from '../assets/signman2.png';
import bluebase from '../assets/bluebase.png';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Testimonials from './Testimonials';
import Timeline from './Timeline';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Roadmap from './Roadmap';

export function Body(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  const [eight, setEight] = useState('visible');
  const [tenth, setTenth] = useState('hidden');
  useEffect(() => {
    if (props.isEight == true) {
      setEight('visible');
      setTenth('hidden');
      console.log('eighth');
    } else {
      setEight('hidden');
      setTenth('visible');
      console.log('tenth');
    }
  }, [props.isEight]);

  function handleSwitch() {
    props.isEight ? props.setIsEight(false) : props.setIsEight(true);
  }

  const fadeInTop = keyframes`
  from { margin-top: -100px; opacity: 0 }
  to { margin-top: 0px; }
`;
  const fadeInTopAnim = `${fadeInTop} 0.5s ease-in-out`;

  return (
    <VStack w='100vw' overflow={'hidden'} mb='50px' bg='transparent'>
      <Stack
        w={{ base: '100%', lg: '80%' }}
        py='20px'
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: '0px', lg: '10px' }}
        justifyContent={'center'}
        alignItems={'center'}
        bg='black'
        borderRadius={'20px'}
      >
        <Box
          w={{
            base: '100%',
            lg: '50%',
          }}
          mb='20px'
          px='20px'
          // bg='blue'
        >
          <Text
            fontSize={{ base: '17px', md: '25px' }}
            fontWeight='bold'
            textAlign='left'
            data-aos='fade-down'
            color='white'
          >
            Reasons why you should NOT consider LucidDreams ?
          </Text>
          <Text
            color='#858585'
            textAlign='justify'
            fontSize={{ base: '15px', md: '20px' }}
            fontWeight='light'
            data-aos='fade-up'
          >
            We provide a holistic development platform where students can
            discover themselves and take the right steps toward their future
            under expert guidance to become the best in their strengths. Join a
            community where you can find like-minded individuals, experts, and a
            mentor to become your best version. The best "Educational
            supplement" that you need for a "Balanced learning experience" at
            the most reasonable price. Don't believe us, here's why they chose
            luciddreams…
          </Text>
        </Box>

        <Box
          w={{
            base: '100%',
            sm: '80%',
            lg: '45%',
          }}
          boxShadow='lg'
          borderRadius='10px'
          // bg='red'
          display='flex'
          justifyContent='center'
          alignItems='center'
          data-aos='fade-up'
          data-aos-delay='300'
        >
          <Testimonials />
        </Box>
      </Stack>
    </VStack>
  );
}
