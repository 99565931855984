import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '../Context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Box,
  Text,
  useMediaQuery,
  Divider,
  Link,
  Stack,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
  Heading,
  Flex,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { InputNormal } from './Profile';

export default function Checkout() {
  const { email, phone, name, isLogin, setIsLogin, product } =
    useContext(GlobalContext);

  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [purpose, setPurpose] = useState('');
  const [address, setAddress] = useState('');
  const [Temail, setTEmail] = useState(email);
  const [Tphone, setTPhone] = useState(phone);
  const [Tname, setTName] = useState(name);

  const navigate = useNavigate();
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    if (product === '0') {
      navigate('/');
    } else {
      switch (product) {
        case '1':
          setPurpose('Trimester Plan');
          setAmount('800');
          break;
        case '2':
          setPurpose('Mid-Year Pass');
          setAmount('1500');
          break;
        case '3':
          setPurpose('One-Year Sprint');
          setAmount('2500');
          break;
        case '4':
          setPurpose('Lucid Plus');
          setAmount('3499');
          break;
      }
    }
  }, []);

  const updateAddress = async () => {
    var data = { address: address };
    try {
      const response = await axios.post(
        `https://luciddreams.in/api/update/address`,
        // `https://luciddreams-site.onrender.com/api/update/address`,
        // `http://localhost:5000/api/update/address`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status == 200) {
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
      // toast({
      //   title: 'Error',
      //   description: error.response.data.message,
      //   status: 'error',
      //   duration: 10000,
      //   isClosable: true,
      // });
    }
  };

  const toast = useToast();
  const getPaymentLink = async () => {
    if (address === '') {
      toast({
        title: 'Error',
        description: 'Please enter valid address',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
    updateAddress();
    setLoading(true);
    var data = {
      amount: amount,
      purpose: purpose,
      name: Tname,
      email: Temail,
      phone: Tphone,
    };
    // console.log(data);
    try {
      const response = await axios.post(
        'https://luciddreams.in/api/purchase/payment',
        // `https://luciddreams-site.onrender.com/api/purchase/payment`,
        // `http://localhost:5000/api/purchase/payment`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status == 200) {
        setLoading(false);
        // console.log(response.data);
        window.open(response.data.longurl);
        setLink(response.data.longurl);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: 'Error',
        description: 'Please enter valid details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      justifyContent={{ base: 'flex-start', md: 'center' }}
      alignItems={{ base: 'center', md: 'flex-start' }}
      pt={'3rem'}
      w='100%'
      minH='100vh'
      // bg='aqua'
    >
      <VStack
        borderRadius={'20px'}
        // boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
        bg={'linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'}
        w={{ base: '95%', md: '20rem' }}
        h='fit-content'
        p={'1rem'}
      >
        <Text fontSize='1.5rem' fontWeight='bold'>
          Order Summary
        </Text>
        <Box w='100%' h={'1px'} bg={'rgba(0,0,0,0.3)'} />
        <HStack w='100%' justifyContent={'space-between'}>
          <Text>Plan : </Text>
          <Text>{purpose}</Text>
        </HStack>
        <HStack w='100%' justifyContent={'space-between'}>
          <Text>Amount : </Text>
          <Text>₹ {amount}</Text>
        </HStack>
        <Box w='100%' h={'1px'} bg={'rgba(0,0,0,0.3)'} />
        <HStack w='100%' justifyContent={'space-between'}>
          <Text fontSize='1.1rem' fontWeight='bold'>
            Order Total :{' '}
          </Text>
          <Text>₹ {amount}</Text>
        </HStack>
      </VStack>
      <VStack
        borderRadius={'20px'}
        boxShadow={'0 4px 30px rgba(0, 0, 0, 0.3)'}
        w={{ base: '95%', md: '50rem' }}
        h='fit-content'
        p={'1rem'}
      >
        <InputNormal val={Tname} setVar={setTName} placeholder='Name'>
          Name
        </InputNormal>
        <InputNormal val={Temail} setVar={setTEmail} placeholder='Email'>
          Email
        </InputNormal>
        <InputNormal val={Tphone} setVar={setTPhone} placeholder='Phone'>
          Phone
        </InputNormal>
        <InputNormal val={address} setVar={setAddress} placeholder='Address'>
          Address
        </InputNormal>
        <Button
          p='0.5rem'
          // variant={'unstyled'}
          bg={'linear-gradient(90deg, #5653FF 0%, #6B4FFC 0.01%, #8C49F7 100%)'}
          onClick={() => {
            getPaymentLink();
          }}
          isLoading={loading}
        >
          Proceed to Payment
        </Button>
      </VStack>
      {/* 
      <Text>{link}</Text> */}
    </Stack>
  );
}
