import React, { useState, createContext } from 'react';
// import dayjs from 'dayjs';

export const GlobalContext = createContext();

export const GlobalProvider = props => {
  const [showLogin, setShowLogin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [profile, setProfile] = useState('');
  const [password, setPassword] = useState('');
  const [product, setProduct] = useState('0');
  return (
    <GlobalContext.Provider
      value={{
        showLogin,
        setShowLogin,
        email,
        setEmail,
        phone,
        setPhone,
        name,
        setName,
        username,
        setUsername,
        profile,
        setProfile,
        password,
        setPassword,
        isLogin,
        setIsLogin,
        product,
        setProduct,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
