import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Divider,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Image,
  Button,
  Icon,
} from '@chakra-ui/react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

export default function Testimonials() {
  const testimonials = [
    {
      name: 'Roopak',
      pos: 'Class 8',
      text: 'The activities and videos are fun to watch, I am writing everything and very happy to see my progress.',
    },
    {
      name: "Theertha's Parent",
      pos: 'Class 9',
      text: 'Theertha always struggled with math and this affected her marks too. This test has given us a different perspective to polish her strengths and work on her weakness. I would recommend this to all parents to get to know your child better and provide them better support',
    },
    {
      name: 'Aromal',
      pos: 'Class 12',
      text: 'Very helpful and on to the point',
    },
    {
      name: 'Liya',
      pos: 'Class 10',
      text: 'The most fun way to learn things, Thank you luciddreams family ',
    },
  ];

  let slides1 = [
    <Testimonial data={testimonials[0]} />,
    <Testimonial data={testimonials[1]} />,
    <Testimonial data={testimonials[2]} />,
  ];

  return (
    <Box w='100%' borderRadius='10px'>
      <Splide
        options={{
          type: 'loop',
          autoplay: true,
          interval: 5000,
          arrows: false,
          pagination: false,
          // direction: 'ttb',
          // height: '100px',
          // heightRatio: '0.1',
        }}
        hasTrack={false}
      >
        <SplideTrack>
          {testimonials.map((item, index) => (
            <SplideSlide>
              <Testimonial data={testimonials[index]} />
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </Box>
  );
}

function Testimonial(props) {
  return (
    <Box
      // backdropFilter='blur(10px)'
      // border='1px solid rgba(255, 255, 255, 0.3)'
      // boxShadow='0 4px 30px rgba(0, 0, 0, 0.1)'
      // borderRadius='10px'
      // bg='rgba(255, 255, 255, 0.22)'
      h={{
        base: '300px',
        sm: '300px',
        md: '350px',
        lg: '400px',
        xl: '350px',
      }}
      w='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box w='100%' px={{ base: '15px', md: '15px' }}>
        <Text
          fontSize={{
            base: '15px',
            md: '20px',
          }}
          color='whiteAlpha.700'
          fontWeight='light'
          mb={{ base: '5px', md: '7px', lg: '7px' }}
        >
          "{props.data.text}"
        </Text>
        <Divider orientation='horizontal' />
      </Box>
      <Box
        w='100%'
        display='flex'
        mt={{ base: '5px', md: '10px', lg: '10px' }}
        px={{ base: '15px', md: '15px' }}
        gap='10px'
      >
        <Box display='flex' flexDirection='column' justifyContent='flex-start'>
          <Text
            fontSize={{ base: '15px', lg: '17px', xl: '20px' }}
            fontWeight='bold'
            color='white'
          >
            {props.data.name}
          </Text>
          <Text
            fontSize={{ base: '12px', lg: '15px', xl: '20px' }}
            fontWeight='semibold'
            color='whiteAlpha.500'
          >
            {props.data.pos}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

{
  /* <Splide
          options={{
            rewind: true,
            autoplay: true,
            arrows: false,
          }}
          hasTrack={false}
        >
          <Box w='100%'>
            <SplideTrack>
              {testimonials.map(t => (
                <SplideSlide>
                  <Box w='100%' px={{ base: '', md: '60px' }} bg='#23B5D3'>
                    <Text
                      fontSize={{
                        base: '20px',
                        md: '60px',
                        lg: '70px',
                        xl: '80px',
                      }}
                      color='black'
                      fontWeight='semibold'
                    >
                      "{t.text}"
                    </Text>
                    <Divider orientation='horizontal' />
                  </Box>
                  <Box
                    display='flex'
                    mt='15px'
                    px={{ base: '', md: '60px' }}
                    gap='10px'
                  >
                    <Box
                      w={{ base: '40px', lg: '80px' }}
                      h={{ base: '40px', lg: '80px' }}
                      borderRadius='100px'
                      bg='grey'
                    ></Box>
                    <Box
                      h={{ base: '40px', lg: '80px' }}
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-start'
                    >
                      <Text
                        fontSize={{ base: '15px', lg: '30px' }}
                        fontWeight='bold'
                        color='black'
                      >
                        {t.name}
                      </Text>
                      <Text
                        fontSize={{ base: '12px', lg: '20px' }}
                        fontWeight='semibold'
                        color='blackAlpha.500'
                      >
                        {t.pos}
                      </Text>
                    </Box>
                  </Box>
                </SplideSlide>
              ))}
            </SplideTrack>
            <Box
              bottom={{ base: '-30', sm: '-85', md: '-50' }}
              position='absolute'
              w='50%'
              ml='25%'
              bg='black'
            >
              <Box className='splide__progress'>
                <div className='splide__progress__bar' />
              </Box>
            </Box>
          </Box>
        </Splide> */
}
