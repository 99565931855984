import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  Stack,
  VStack,
  Heading,
  Flex,
  IconButton,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';

export default function Refund() {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <VStack align='flex-start' pb='5rem' w={{ base: '90%', md: '80%' }}>
      <Heading w='100%' textAlign='center' size='2xl' py='5rem'>
        Refund Policy
      </Heading>
      <Text>
        LucidDreams. renders services including, but not limited to, personality
        assessments, counselling and mentorship, which act as a bonafide guide
        to the user and the services are rendered as soon as availed, therefore
        these will be the terms and conditions of refunds:
      </Text>
      <OrderedList styleType='' spacing={'0.5rem'}>
        <ListItem>
          A refund can only be initiated within 7 days of the nd of the 2-month
          community access period.
        </ListItem>
        <ListItem>
          A full refund can only be initiated in the following conditions:
          <OrderedList styleType='lower-alpha' spacing={'1rem'}>
            <ListItem>
              The user is dissatisfied with the service for any of the following
              reasons:
              <OrderedList styleType='lower-roman'>
                <ListItem>
                  The counsellor didn’t spend the designated/mentioned amount of
                  time with the user or
                </ListItem>
                <ListItem>
                  The counsellor wasn’t able to provide the desired clarity to
                  the user and their family members
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Before a refund is initiated, the following will be offered by
              LucidDreams to the user:
              <OrderedList styleType='lower-roman'>
                <ListItem>
                  A review of the case with LucidDreams’s counsellor quality
                  team - to see if the case can be resolved amicably.
                </ListItem>
                <ListItem>
                  An additional free career counselling session with a different
                  counsellor to achieve the desired clarity.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Only if neither of the above is successful, will a refund be
              initiated.
            </ListItem>
            <ListItem>
              Please note that the following activities aren’t included in the
              counselling sessions and refunds for these reasons will not be
              initiated:
              <OrderedList styleType='lower-roman'>
                <ListItem>
                  Counselling for personal/emotional issues. This requires a
                  session with a clinical psychologist or counsellor.
                </ListItem>
                <ListItem>
                  Compelling users to change careers towards a particular
                  direction. The shortlisted careers must be based on the users’
                  inherent interests, as this will help them tackle the
                  challenges they will face during the course of their career.
                  Without a deep interest in the field, the user will struggle
                  to grow and succeed in their career.
                </ListItem>
                <ListItem>
                  Sharing details of courses/colleges/universities. This
                  information will be provided in the community section, and can
                  be accessed once the user has completed their career
                  counselling session and accepted their profiles.
                </ListItem>
              </OrderedList>
            </ListItem>
          </OrderedList>
        </ListItem>
      </OrderedList>
    </VStack>
  );
}
